<div class="row" id="kvittering">

    <!-- <div *ngIf="kvitteringData.kvitteringsside == '20'" class="col-12">
        <h1 class="text-center">{{kvitteringData.overskrift}}</h1>
    </div> -->
    <div *ngIf="kvitteringData.kvitteringsside === '20'" class="col-12">
        <h1 class="text-center">Afslag - du har søgt for tidligt</h1>
    </div>
    <div *ngIf="kvitteringData.kvitteringsside !== '20'" class="col-12">
        <h1 class="text-center">Afslag</h1>
    </div>
    <div *ngIf="!(kvitteringData.kvitteringsside === '22' || kvitteringData.kvitteringsside === '24')" class="col-12">
        <span class="text-danger">Du har ikke ret til fødsels-støtte</span>
    </div>
    <div *ngIf="kvitteringData.kvitteringsside === '22' || kvitteringData.kvitteringsside === '24'" class="col-12">
        <span class="text-danger">Du har ikke ret til fødsels-støtte som enlig forsøger</span>
    </div>
    <br />
    <br />
    <div *ngIf="kvitteringData.kvitteringsside === '20'" class="col-12">
        <p><strong style="color: black;">Du har oplyst, at du har termin i {{ kvitteringData.termin }} </strong></p>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '20'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>{{ kvitteringData.morFar }} og tidligst søge,
            en måned før du kan få fødsels-støtte udbetalt. Du kan derfor tidligst søge i {{ kvitteringData.terminsMaaned }}.
            <br /><br />
            Du kan læse om retten til <a style="text-decoration: underline" href="https://www.su.dk/"> fødsels-støtte på
                su.dk</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '21'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi det er for længe siden, du fik barn.
            <br /><br />
            Ifølge reglerne kan du højst få 9 måneder med fødsels-støtte. Når dit barn er født,
            før du starter på din uddannelse, skal vi trække de måneder fra, der er mellem fødselsmåneden og din
            uddannelses startmåned.
            Da dit barn er født i {{ kvitteringData.termin }}, har du derfor ikke ret til fødsels-støtte, da der er mere
            end 9 måneder imellem.
            <br /><br />
            Du kan læse om retten til <a style="text-decoration:underline" href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '22'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi det er for længe siden, du fik barn.
            <br /><br />
            Ifølge reglerne kan du højst få 12 måneder med fødsels-støtte. Når dit barn er født, før du starter på din
            uddannelse,
            skal vi trække de måneder fra, der er mellem fødselsmåneden og din uddannelses startmåned. Da dit barn er
            født i {{ kvitteringData.termin }},
            har du derfor ikke ret til fødsels-støtte, da der er mere end 12 måneder imellem.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '23'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi det er for længe siden, du fik barn.
            <br /><br />
            Ifølge reglerne kan du højst få 9 måneder med fødsels-støtte. Når dit barn er født, imens du er på orlov,
            skal vi trække de måneder fra, der er imellem fødselsmåneden og den måned du genoptog din uddannelse.
            Da dit barn er født i {{ kvitteringData.termin }}, har du derfor ikke ret til fødsels-støtte, da der er mere
            end 9 måneder imellem.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '24'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi det er for længe siden, du fik barn.
            <br /><br />
            Ifølge reglerne kan du højst få 12 måneder med fødsels-støtte. Når dit barn er født,
            imens du er på orlov, skal vi trække de måneder fra, der er imellem fødselsmåneden og den måned du genoptog
            din uddannelse.
            Da dit barn er født i {{ kvitteringData.termin }}, har du derfor ikke ret til fødsels-støtte, da der er mere
            end 12 måneder imellem.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '25'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi det er for længe siden, du fik barn. Når man går på en
            ungdomsuddannelse,
            kan du ifølge reglerne senest få udbetalt fødsels-støtte 18 måneder efter dit barns fødselsmåned.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '26'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi du har oplyst, at terminsdatoen for dit barns fødsel er
            {{ kvitteringData.termin }},
            hvilket er efter den dato, vi har registreret, du afslutter din uddannelse.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '27'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi man ifølge reglerne ikke kan få fødsels-støtte,
            mens man er i lønnet praktik eller på et projektorienteret forløb med løn i udlandet, der afslutter en
            uddannelse.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '28'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi der er mere end en måned til, du genoptager din uddannelse efter orlov.
            Du kan først søge om fødsels-støtte måneden før, du genoptager din uddannelse.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '29'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi vi ikke har registreret, at du går på en uddannelse, der er godkendt
            til SU.
            <br /><br />
            Du skal først søge om almindelig SU igen (også selv om du eventuelt har brugt al din SU).
            Når du får svar på den ansøgning som Digital Post, kan du søge fødsels-støtte igen. Kontakt eventuelt en
            SU-medarbejder på dit uddannelsessted for vejledning.
            <br /><br />
            <a style="text-decoration:underline" href="https://www.su.dk/">Søg SU her</a>
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '30'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi du tidligere har brugt de måneder med fødsels-støtte, du har ret til.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '31'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi det er for længe siden, du fik barn.
            <br /><br />
            Ifølge reglerne kan du højst få 9 måneder med fødsels-støtte. Når dit barn er født, før du starter på din uddannelse,
            skal vi trække de måneder fra, der er imellem fødselsmåneden og din nye uddannelses startmåned.
            <br /><br />
            Da dit barn er født i {{ kvitteringData.termin }}, har du derfor ikke ret til fødsels-støtte, da der er mere
            end 9 måneder imellem.
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '32'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi du modtager anden offentlig støtte.<br />
              Du kan først søge om fødsels-støtte i den sidste måned, du modtager anden offentlig støtte.
            <br /><br />
            Du kan læse om <a style="text-decoration:underline"
            href="https://www.su.dk/">anden offentlig støtte på su.dk her.</a>
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '33'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi du som udenlandsk statsborger skal være ligestillet med danske statsborgere, før du kan få SU og fødsels-støtte.
            <br /><br />
            Du kan læse om <a style="text-decoration:underline"
            href="https://www.su.dk/">ligestilling på su.dk her.</a>
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '34'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi du har søgt for tidligt. Du kan tidligst få SU og fødsels-støtte kvartalet efter, du fylder 18 år. 
            <br /><br />
            Du kan læse om, <a style="text-decoration:underline"
                href="https://www.su.dk/">hvornår du kan søge SU på su.dk her.</a>
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '35'" class="col-12 pt-2">
        <h3>Hvorfor får du afslag</h3>
        <span>Du får afslag på din ansøgning, fordi du ikke opfylder betingelserne for at få SU og fødsels-støtte. 
            <br /><br />
            Du kan læse om <a style="text-decoration:underline"
            href="https://www.su.dk/">betingelserne for SU på su.dk her.</a>
            <br /><br />Du kan læse om retten til <a style="text-decoration:underline"
                href="https://www.su.dk/">fødsels-støtte på su.dk her.</a>
            <br /><br />Du modtager også denne afgørelse som Digital Post via borger.dk.
        </span>
    </div>

    <div *ngIf="kvitteringData.kvitteringsside === '20'" class="col-12 pt-4">
        <h3>Hvad sker der nu?</h3>
        <span>Du kan søge fødsels-støtte igen, når du opfyder betingelsen.</span>
    </div>

    <div class="col-12 pt-4">
        <h3>Klagevejledning</h3>
        <span>Ønsker du at <a style="text-decoration:underline" href="https://www.su.dk/">klage over denne afgørelse</a>,
            skal du sende klagen som Digital Post via borger.dk til Uddannelses- og Forskningsstyrelsen.
            <br />
            I emnefeltet skal du skrive: "Klage til Ankenævnet for Statens Uddannelsesstøtteordninger".
            Vi skal have modtaget din klage senest 4 uger fra den dag, du modtager afgørelsen som Digital Post.</span>
    </div>

    <div class="col-12 pt-3">
        <div class="pt-3">
            <button  (click)="gaaTilForside()" type="submit" class="btn btn-primary btn-md d-block w-100">
                Tilbage til forsiden
            </button>
        </div>
    </div>
</div>