<div class="row" id="kvittering">

  <div class="col-12">
    <h2 class="text-center">Kvittering</h2>
  </div>
  <br />
  <br />

  <div class="col-12">
    <h3>Du har ret til {{ kvitteringData.tildeltKlip }} {{ kvitteringData.maanedTekstTildeltKlip }} med fødsels-støtte.</h3>
  </div>
  <br />
  <br />

  <div class="col-12">
    <p class="fw-bold" style="color: rgb(255, 0,0 )">
      Du kan nu planlægge udbetalingen af din fødsels-støtte
    </p>
  </div>

  <div *ngIf="kvitteringData.kvitteringsside === '1'" class="col-12">
    <p class="fw-bold" style="color: rgb(0, 0, 0)">
      Du har oplyst, at du har termin i {{ kvitteringData.termin }}
    </p>
  </div>

  <div *ngIf="kvitteringData.kvitteringsside === '4'" class="col-12 pt-2">
    <h5>Sådan har vi regnet din fødsels-støtte ud</h5>
    Ifølge reglerne kan du højst få {{ kvitteringData.resultatAfUdregning }} måneder med fødsels-støtte.
    Da du har brugt {{ kvitteringData.fraTrukketKlip }} {{ kvitteringData.maanedTekstFraTrukketKlip }} fødsels-støtte på en
    tidligere uddannelse, har du ret
    til {{ kvitteringData.resultatAfUdregning }} – {{ kvitteringData.fraTrukketKlip }} = {{ kvitteringData.tildeltKlip }}
    {{ kvitteringData.maanedTekstTildeltKlip }} med fødsels-støtte.
    <br /><br />
  </div>

  <div *ngIf="kvitteringData.kvitteringsside === '5'" class="col-12 pt-2">
    <h5>Sådan har vi regnet din fødsels-støtte ud</h5>
    Ifølge reglerne kan du højst få 9 måneder med fødsels-støtte.
    Er dit barn født, før du starter på din uddannelse, skal vi trække de måneder fra, der er imellem fødselsmåneden og
    din nye uddannelses startmåned.
    Da dit barn er født i {{ kvitteringData.termin }}, har du derfor ret til {{ kvitteringData.resultatAfUdregning }} –
    {{ kvitteringData.fraTrukketKlip }} = {{ kvitteringData.tildeltKlip }} {{ kvitteringData.maanedTekstTildeltKlip }} med
    fødsels-støtte.
    <br /><br />
  </div>

  <div *ngIf="kvitteringData.kvitteringsside === '6'" class="col-12 pt-2">
    <h5>Sådan har vi regnet din fødsels-støtte ud</h5>
    Ifølge reglerne kan du højst få 9 måneder med fødsels-støtte.
    Er dit barn født, før du starter på din uddannelse, skal vi trække de måneder fra, der er imellem fødselsmåneden og
    din uddannelses startmåned.
    Da dit barn er født i {{ kvitteringData.termin }}, har du derfor ret til {{ kvitteringData.resultatAfUdregning }} –
    {{ kvitteringData.fraTrukketKlip }} = {{ kvitteringData.tildeltKlip }} {{ kvitteringData.maanedTekstTildeltKlip }} med
    fødsels-støtte.
    <br /><br />
  </div>

  <div *ngIf="kvitteringData.kvitteringsside === '7'" class="col-12 pt-2">
    <h5>Sådan har vi regnet din fødsels-støtte ud</h5>
    Ifølge reglerne kan du højst få 9 måneder med fødsels-støtte. Er dit barn født, før du starter på din uddannelse,
    skal vi trække de måneder fra,
    der er mellem fødselsmåneden og den måned, du genoptog din uddannelse efter orlov.
    Da dit barn er født i {{ kvitteringData.termin }}, har du derfor ret 
    til {{ kvitteringData.resultatAfUdregning }} –
    {{ kvitteringData.fraTrukketKlip }} = {{ kvitteringData.tildeltKlip }} 
    {{ kvitteringData.maanedTekstTildeltKlip }} med fødsels-støtte.
    <br /><br />
  </div>

  <div *ngIf="kvitteringData.uddBopal === 'N' && kvitteringData.IkkeRegisteretBarn === true" class="col-12 pt-2">
    <h5>Kontrol af fødslen</h5>
    Vi får automatisk besked fra CPR, når dit barn er født. Derefter skriver vi til dig via Digital Post på borger.dk.
    <br /><br />
  </div>

  <div
    *ngIf="kvitteringData.uddBopal === 'J' && kvitteringData.IkkeRegisteretBarn === true && !kvitteringData.harUddBopalEnlig"
    class="col-12 pt-2">
    <h5>Send dokumentation for dit barns fødsel</h5>
    Da du bor i udlandet, får vi ikke automatisk besked, når dit barn er født.
    Du skal derfor sende dokumentation for fødslen til os - <a style="text-decoration:underline"
      href="https://post.borger.dk/send/28a46f78-c2d8-4256-9ef3-a9256c840ed4/cfbdb82d-f5d6-4e7f-b1e6-2f8c2c9e3b4a/">send
      dokumentationen via Digital Post fra borger.dk her.</a>
    <br /><br />
  </div>

  <div *ngIf="kvitteringData.enlig === 'J' && !kvitteringData.harUddBopalEnlig " class="col-12">
    <h5>Søgt som enlig</h5>
    Da du har søgt fødsels-støtte som enlig forsørger, skal du sende dokumentation for,
    at du får ekstra børnetilskud fra Udbetaling Danmark – send som <a style="text-decoration:underline"
      href="https://post.borger.dk/send/28a46f78-c2d8-4256-9ef3-a9256c840ed4/71abf869-8618-4892-a082-2268660c81bf/">Digital
      Post via dette link.</a>
    <br /><br />
    Når vi har registret, at du har ret til fødsels-støtte som enlig forsørger, kan du udfylde din udbetalingsplan med
    de op til 3 måneder ekstra med fødsels-støtte.
    <br /><br />
  </div>

  <div *ngIf="kvitteringData.uddBopal === 'J' && kvitteringData.enlig === 'J'" class="col-12">
    <h5>Bopæl i udlandet og enlig</h5>
    Da du bor i udlandet, skal du sende dokumentation for fødslen, og for at du er enlig forsøger til os – send som <a
      style="text-decoration:underline"
      href="https://post.borger.dk/send/28a46f78-c2d8-4256-9ef3-a9256c840ed4/cfbdb82d-f5d6-4e7f-b1e6-2f8c2c9e3b4a/">Digital
      Post via dette link.</a>
    <br /><br />
    Når vi har registret, at du har ret til fødsels-støtte som enlig forsørger, kan du udfylde din udbetalingsplan med
    de op til 3 måneder ekstra med fødsels-støtte.
    <br /><br />
  </div>

  <div class="col-12">
    Du modtager også denne afgørelse som Digital Post via borger.dk
    <br /><br />
  </div>

  <!-- <div *ngIf="kvitteringData.kvitteringsside != '1'" class="col-12">
    <p><strong style="color: black;">Vi kan se i CPR-registret, at dit barn blev født den</strong></p>
  </div> -->

  <!-- <div *ngIf="kvitteringData.kvitteringsside == '1' && kvitteringData.enlig == 'J'" class="col-12 pt-2">
    <h5>Du er godkendt som enlig forsørger</h5>
    Du er blevet godkendt til 12 måneders fødsels-støtte, fordi vi har fået oplysninger om, at du er enlig forsørger.
    <br><br>Du kan læse mere om fødsels-støtte som enlig forsørger <a href="https://www.su.dk/">her.</a>
  </div>

  <div *ngIf="kvitteringData.kvitteringsside == '1' && kvitteringData.enlig == 'J'" class="col-12 pt-2">
    <h5>Du er godkendt som enlig forsørger</h5>
    Du er godkendt til yderligere 3 måneders fødsels-støtte, hvis vi efter fødslen får besked om, at du som enlig
    forsøger er berettiget til det ekstra børnetilskud fra Udbetling Danmark.
    <br><br>
    Hvis du er udenlandsk statsborger, dit barn er anbragt uden for hjemmet eller du bor i udlandet, kan vi ikke hente
    oplysningerne automatisk. I de tilfælde skal du selv dokumentere, at du er enlig forsørger. Du vil modtage et brev
    om det som
    Digital Post via borger.dk.
    <br><br>
  </div> -->

  <!-- <div *ngIf="kvitteringData.kvitteringsside == '5'" class="col-12 pt-2">
    <h5>Din godkendelse</h5>
    Du er blevet godkendt til 6 måneders fødsels-støtte, fordi du blev forældre på din tidligere uddannelse.
    <br><br>Du kan læse mere om fødsels-støtte som enlig forsørger <a href="https://www.su.dk/">her.</a>
    <br><br>
  </div>

  <div *ngIf="kvitteringData.kvitteringsside == '6'" class="col-12 pt-2">
    <h5>Din godkendelse</h5>
    Du er blevet godkendt til 5 måneders fødsels-støtte, fordi du blev forældre, inden du startede på din nuværende
    uddannelse.
    <br><br>
  </div>

  <div *ngIf="kvitteringData.kvitteringsside == '7'" class="col-12 pt-2">
    <h5>Din godkendelse</h5>
    Du er blevet godkendt til 5 måneders fødsels-støtte, fordi du blev forældre, mens du havde orlov på din nuværende
    uddannelse.
  </div>

  <div *ngIf="kvitteringData.tildeltKlipVis" class="col-12 pt-2">
    Ifølge reglerne kan du højst få [9] måneder med fødsels-støtte. Er dit barn født, før du starter på din uddannelse,
    skal vi trække de måneder fra, der er mellem fødselsmåneden og den måned,
    du genoptog din uddannelse efter orlov. Da dit barn er født i [MÅNED], har du derfor ret til 9 – Y = X måneder med fødsels-støtte.
    Du kan læse mere om fødsels-støtte <a href="https://www.su.dk/">her.</a> 
    <br><br>
    Du modtager også denne afgørelse som Digital Post via borger.
    <br><br> 
  </div> -->

  <div class="col-12">
    <h5>Planlæg dine udbetalinger</h5>
    Før du kan få fødsels-støtte udbetalt, skal du planlægge, hvornår og hvordan du vil have støtten udbetalt.
    <!-- <br><br>Du kan ændre din udbetalingsplan løbende.
      <br><br> -->
    <!-- Du modtager også din afgørelse som Digital Post via borger.dk -->
  </div>
  <div class="col-12 pt-3">
    <div class="pt-3">
      <button (click)="gaaTilUdbetaling()" type="submit" class="btn btn-primary btn-lg d-block w-100">Planlæg
        udbetalinger</button>
    </div>
  </div>
  <div class="col-12 pt-3">
    <div class="pt-3">
      <button (click)="gaaTilForside()" type="submit" class="btn btn-primary btn-md d-block w-100">Tilbage til
        forsiden</button>
    </div>
  </div>
</div>