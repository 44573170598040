import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, NavigationStart, NavigationEnd } from '@angular/router';

import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

import { UdbetalingKvitteringComponent } from './forretnings-sider/udbetaling/udbetaling-kvittering/udbetaling-kvittering.component';
import { OverblikComponent } from './forretnings-sider/overblik/overblik.component';
import { AnsoegningComponent } from './forretnings-sider/ansoegning/ansoegning.component';
import { AnsoegningGuard } from './forretnings-sider/ansoegning/ansoegning.guard';
import { FuldmagtGuard } from './forretnings-sider/fuldmagt/fuldmagt.guard';
import { LoggetPaaGuard } from './shared/api-session/logget-paa.guard';
import { KvitteringComponent } from './forretnings-sider/kvittering/kvittering.component';
import { AfslagComponent } from './forretnings-sider/kvittering/Afslag/afslag/afslag.component';
import { GodkendelseComponent } from './forretnings-sider/kvittering/godkendelse/godkendelse/godkendelse.component';
import { SharedComponent } from './forretnings-sider/kvittering/shared/shared/shared.component';
import { UdbetalingComponent } from './forretnings-sider/udbetaling/udbetaling.component';

import { KvitteringGuard } from './forretnings-sider/kvittering/kvittering.guard';
import { UfmSessionLogafComponent, UfmSessionTimeoutComponent, UfmSessionDemoAuthComponent } from '@ufmit/ufm-session';

// todo: der mangler sandsynligvis en fuldmagtsguard. Se ungdomskort og kilometerpenge.
const routes: Routes = [
    { path: '', component: OverblikComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard] },
    { path: 'ansoegning', component: AnsoegningComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, AnsoegningGuard] },
    { path: 'kvittering', component: KvitteringComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, KvitteringGuard]},
    { path: 'udbetaling-kvittering', component: UdbetalingKvitteringComponent , canActivate: [LoggetPaaGuard, FuldmagtGuard]},
    { path: 'afslag', component: AfslagComponent , canActivate: [LoggetPaaGuard, FuldmagtGuard]},
    { path: 'godkendelse', component: GodkendelseComponent , canActivate: [LoggetPaaGuard, FuldmagtGuard]},
    { path: 'shared', component: SharedComponent , canActivate: [LoggetPaaGuard, FuldmagtGuard]},
    { path: 'udbetaling', component: UdbetalingComponent , canActivate: [LoggetPaaGuard, FuldmagtGuard]},
    { path: 'logaf', component: UfmSessionLogafComponent , canActivate: [LoggetPaaGuard, FuldmagtGuard]},
    { path: 'timeout', component: UfmSessionTimeoutComponent , canActivate: [LoggetPaaGuard, FuldmagtGuard]},
    { path: 'demo-auth', component: UfmSessionDemoAuthComponent },
    { path: '**', redirectTo: '/' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
    constructor(private router: Router, private userTrackingService: UfmUserTrackingService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (['/timeout'].indexOf(event.url) > -1 && !router.navigated) {
                    // retur til forside ved reload
                    router.navigate(['/']);
                    return;
                }
            }
            if (event instanceof NavigationEnd) {
                this.userTrackingService.sendUrl(event.urlAfterRedirects);
            }
        });
    }
}
