import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AnsoegningService } from '../ansoegning/ansoegning.service';
import { ApiAnsoegning} from '../ansoegning/api-ansoegning';

@Injectable({
  providedIn: 'root'
})
export class KvitteringGuard  {

  constructor(private ansoegningService: AnsoegningService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const ansoegning: ApiAnsoegning  = this.ansoegningService.hentSendtAnsoegning();
    let gemteKvitteringData = JSON.parse(sessionStorage.getItem('kvitteringData'));
    if(!ansoegning && !gemteKvitteringData) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}
