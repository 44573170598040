<style>
  @media screen and (min-width: 500px) {
  .pc {
  }
}
 @media screen and (max-width: 500px) {
  .pc {
    display: none !important;
  }
}
 @media screen and (max-width: 500px) {
  .mobil {
  }
}
@media screen and (min-width: 500px) {
  .mobil {
    display: none !important;
  }
}
</style>
<form *ngIf="formUdbetaling" [formGroup]="formUdbetaling">
    <div class="row">
      <div class="col-12 pb-4 text-center pc"> 
        <h1 >Planlæg udbetalinger</h1>
        <br />
        Du kan få op til 9 måneders fødsels-støtte. <br />
        Før du kan få fødsels-støtte udbetalt, skal du planlægge, hvornår og hvordan du vil have støtten udbetalt.<br />
        Du må ikke være erklæret studieinaktiv i forhold til SU-reglerne i måneden før, du ønsker fødsels-støtte udbetalt.
      </div>

      <div class="col-12 pb-4 text-center mobil">
        <span class="">
        <h1 >Planlæg udbetalinger</h1>
      </span>
        <br />
      </div>
      <div class="col-12 mobil">
      <div class="row col-12">
        <div class="col-12" >
          Du kan få op til 9 måneders fødsels-støtte. <br />
          Før du kan få fødsels-støtte udbetalt, skal du planlægge, hvornår og hvordan du vil have støtten udbetalt.<br />
          Du må ikke være erklæret studieinaktiv i forhold til SU-reglerne i måneden før, du ønsker fødsels-støtte udbetalt.
        </div>
      </div>
    </div>
    </div>
    <br />
    <div>
      <div class="row col-12">
        <div class="col-12" >
          <h5>Enkelt fødsels-støtte</h5>
          Vælger du enkelt fødsels-støtte, kan du få fødsels-støtte udbetalt hver måned i {{ restklipFoedsel }} måneder. Enkelt fødsels-støtte er samme beløb som 1 måneds almindelig SU før skat. 
        </div>
      </div>
      <br />
      <div class="row col-12">
        <div class="col-12" >
          <h5>Dobbelt fødsels-støtte</h5>
          Vælger du dobbelt fødsels-støtte, er det samme beløb som 2 måneders almindelig SU før skat. 
          Får du SU-lån, bliver det også fordoblet i de måneder, du får dobbelt fødsels-støtte.  
        </div>
      </div>
      <br />
      <div *ngIf="uddType === 'VU'">
      <div class="row col-12">
        <div class="col-12" >
          <h5>Fødsels-støtte med almindelig SU (kun til videregående uddannelser)</h5>
          Vælger du fødsels-støtte med almindelig SU, kan du få fødsels-støtte udbetalt i maksimalt 9 måneder. Fødsels-støtte med almindelig SU er samme beløb som 2 måneders almindelig SU før skat. <br />
          Da du sammen med fødsels-støtte modtager almindelig SU, er det et krav, at du stadig har SU tilbage i dit klippekort, at du er studieaktiv i forhold til SU-reglerne i udbetalingsperioden, og at du opfylder de generelle betingelser for at få SU. <br /> 
          Den almindelige SU, som du får udbetalt sammen med fødsels-støtte, tæller med i dit SU-klipforbrug og kan derfor få betydning for, om du senere kan opfylde SU-studieaktivitetskravet. Hvis du er i tvivl om din situation i forhold til studieaktivitetskravet, hvis du vælger fødsels-støtte med almindelig SU, skal du kontakte SU-medarbejderen på dit uddannelsessted.  
      </div>
    </div>
  </div>
  </div>
  <br />
   
    <!-- <app-accordion class="col-12"  [h]="2" 
      [overskrift]="'Udfyld din udbetalingsplan'" [vis]="UdbetalingVis"
      [scrollIntoView]="'smart'" (vis$)="aabnedeLukkedeUdbetaling($event)"
      #AlmStoetteAccordion> -->
      <div class="col-12">
      <div *ngIf="inaktiv === 'N'">
      <div class="row " >
        <div class="col-12">
            Her kan du vælge, hvornår og hvordan du vil have fødsels-støtten udbetalt.
            <br /><br />
            Du kan ændre udbetalingsplanen senere. 
            <br /><br />
            Du kan ikke modtage forsørgertillæg og supplerende SU-lån til forsørgere i de måneder, du modtager fødsels-støtte.<br /> 
            Når du har gennemført  ansøgningen om fødsels-støtte, standser vi automatisk udbetalingen af dit eventuelle forsørgertillæg og supplerende SU-lån til forsørgere. 
            <br /><br />
            <div class="pc">

            <div style="overflow:auto;width:400px">
              <div style="float:left">
                Tildelt fødsels-støtte
              </div>
            <div class="text-center" style="border-style: solid;width: 30px;height: 30px;border-color: #46328c;float:right;">
              {{ tildeltKlip }}
              </div>
            </div>
            <br />
            <div style="overflow:auto; width:400px">
              <div style="float: left">
                Resterende fødsels-støtte
              </div>
            <div class="text-center" style="border-style: solid;width: 30px;height: 30px;border-color: #46328c;float:right;">
              {{ restklipFoedselLogisk }}
              </div>
            </div>
              <br />
              <div style="overflow:auto;width:400px">
                <div style="float:left">
                  Planlagt fødsels-støtte 
                </div>
                <div class="text-center" style="border-style: solid;width: 30px;height: 30px;border-color: #46328c;float:right;">
                  {{ brugteKlip }}
                  </div>
                </div>
              </div>
              <br />
               
            <div >
              <div class="pc">
                <div class="row" style="border-top: 1px solid black">
                  <div class="col pt-2 pb-2" >
                    {{ stoetteAar }}
                  </div>
                  <div class="col pt-2 pb-2 pc" style="text-align: center" >
                    Udbetalingstype
                  </div>
                  <div class="col pt-2 pb-2 pc" style="background-color: rgb(196, 184, 184); text-align: center">
                    Forbrug af fødsels-støtte
                  </div>
                </div> 
              </div>
                <div class="mobil">
                <div class="row" >
                  <div class="col pt-2 pb-2" >
                    {{ stoetteAar}}
                  </div>
                  <div class="col pt-2 pb-2 pc" style="text-align: center">
                    Udbetalingstype
                  </div>
                  <div class="col pt-2 pb-2 pc" style="background-color: rgb(196, 184, 184);text-align: center">
                    Forbrug af fødsels-støtte
                  </div>
                </div>
                </div>
                <div *ngFor="let maaned of maaneder">
                  <app-udbetaling-maaned [formUdbetalingMaaned]="getMaaned(maaned)"></app-udbetaling-maaned>
                </div>

                <div class="mobil"> <br /><br /></div>
                <div class="mobil">
                  <div style="overflow: auto">
                    <div style="float: left">
                      Tildelt fødsels-støtte
                    </div>
                  <div class="text-center" style="border-style: solid;width: 30px;height: 30px;border-color: #46328c;float:right">
                    {{ tildeltKlip }}
                    </div>
                  </div>
                  <br />
                  <div style="overflow: auto">
                    <div style="float: left">
                      Resterende fødsels-støtte
                    </div>
                  <div class="text-center" style="border-style: solid;width: 30px;height: 30px;border-color: #46328c;float:right">
                    {{ restklipFoedselLogisk }}
                    </div>
                  </div>
                    <br />
                    <div style="overflow: auto">
                      <div style="float: left">
                        Planlagt fødsels-støtte 
                      </div>
                      <div class="text-center" style="border-style: solid;width: 30px;height: 30px;border-color: #46328c;float:right">
                        {{ brugteKlip }}
                        </div>
                      </div>
                    </div>
                    <div class="mobil"> <br /><br /></div>
                    
                  <div class="pc">
                  <div class="row text-center" class="border border-1 border-dark">
                    Du kan tidligst udfylde din udbetalingsplan for 
                    {{ stoetteAarPlus1 }} den 6. december 
                    {{ stoetteAar }}. Når udbetalingsplanen (for 
                    {{ stoetteAarPlus1 }}) er klar, får du besked som Digital 
                    Post via borger.dk. 
                  </div>
                </div>
                <div class="mobil">
                  <div class="text-center" class="border border-1 border-dark">
                    Du kan tidligst udfylde din udbetalingsplan for 
                    {{ stoetteAarPlus1 }} den 6. december 
                    {{ stoetteAar }}. Når udbetalingsplanen (for 
                    {{ stoetteAarPlus1 }}) er klar, får du besked som Digital 
                    Post via borger.dk. 
                  </div>
                </div>
              </div>
        </div>
    </div>
    <div *ngIf="formUdbetaling.errors && formUdbetaling.errors.forMangeKlip"
         role="alert" aria-atomic="true" class="text-danger">
      Du kan ikke vælge mere end det tildelte, fravælg støtte
    </div>
    <div class="col-12 pt-3" style="width: 400px; margin: auto">
        <button (click)="sendUdbetaling()" type="submit" [disabled]="!formUdbetaling.valid"  class="btn btn-primary btn-lg d-block w-100">
          Indsend udbetalingsplan
        </button>
    </div>
    </div>

    <div *ngIf="inaktiv === 'J'">
      <div class="col-12 pt-1" style="margin: auto; color: red">
      Du er studieinaktiv i én eller flere måneder i perioden. Du kan derfor ikke benytte denne online selvbetjening. Du skal i stedet benytte denne blanket, som sendes til Uddannelses- og Forskningsstyrelsen.
        </div>
      <div class="col-12 pt-3" style="width: 400px; margin: auto">
        <button type="submit" onClick=" parent.location='https://blanket.virk.dk/blanketafvikler/orbeon/fr/nem_b/81_a50006bd3029a70cf3b600a9338495bfd8d627ab/new'" class="btn btn-primary btn-lg d-block w-100">
          Udfyld blanket
        </button> 
    </div>
    </div>
  </div>
    <!-- </app-accordion> -->
</form>
