// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { KeycloakConfig } from 'keycloak-js';

// Keycloak configuration
const keycloakConfig: KeycloakConfig = {
  url: 'https://keycloak.devtest-ufm.eu',
  realm: 'nemlogin',
  clientId: 'foedselsstoette-frontend'
};

export const environment = {
  production: false,
  demo: false,
  keycloak: keycloakConfig,
  fuldmagtsprivilege: 'urn:dk:umit:minsu:privilege_grants',
  foApi: '/api',
  userTracking: {
    brug: 'ga',
    ga:  {
      ejendom: 'UA-11016073-14'
    }
  },
  minsuUrl: 'https://susb-test.su.dk/team2/VisController?ACTION=minSU',
  maxFilUploadBytes: 20971579,
  maxFilUploadTekst: '20Mb',
  test: true
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
