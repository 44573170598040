<form [formGroup]="form">
  <div class="row">
    <app-accordion
      class="col-12"
      [ariaLabel]="'Åben sektion om studieforløbs informationer'"
      [h]="2"
      [overskrift]="'Studieforløb'"
      [status]="status"
      [statusTekst]="statusTekst"
      [vis]="studieforloebVis"
      [scrollIntoView]="'smart'"
      (vis$)="aabnedeLukkedeStudieforloeb($event)"
      (validerFoerFoldSammen$)="validerFoerFoldSammen()"
      #studieforloebAccordion
    >
      <div class="row" (keydown.enter)="$event.preventDefault()">
        <div class="col-12" #topElement></div>
        <div *ngIf="!uddTypeTrueFalse" class="col-12 pb-3">
          <div class="col-12" role="radiogroup" aria-labelledby="uddStatus">
            <div class="row">
              <div class="col-12" id="uddStatus">
                Har du talt med dit uddannelsessted om dit studieforløb i forbindelse med, at du skal være forælder?
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    role="radio"
                    name="uddStatus"
                    id="uddStatusNej"
                    value="Nej"
                    formControlName="uddStatus"
                    (keydown.enter)="$event.preventDefault()"
                  />
                  <label class="form-check-label" for="uddStatusNej">Ja</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    role="radio"
                    name="uddStatus"
                    id="uddStatusJa"
                    value="Ja"
                    formControlName="uddStatus"
                    (keydown.enter)="$event.preventDefault()"
                  />
                  <label class="form-check-label" for="uddStatusJa">Nej</label>
                </div>
              </div>
              <div *ngIf="form.controls.uddStatus.touched && form.controls.uddStatus.errors" class="col-12 text-danger"
                role="alert" aria-atomic="true" #error1>
                Spørgsmål om studieaktivitet skal besvares
              </div>
            </div>
          </div>

          <div *ngIf="studieforloeb2Vis" class="col-12">
            <div [@foldudAmination]="studieforloebAnimation">
              <div class="row">
                <div class="col-12 mb-3 pt-3">
                  <label for="studieforloebJa">Du skal kontakte studieadministrationen på dit uddannelsessted, som kan
                    hjælpe dig med at planlægge dit studieforløb, når du skal være forældrer</label>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="uddTypeTrueFalse" class="col-12 pb-3">
          <div class="col-12" role="radiogroup" aria-labelledby="uddStatus">
            <div class="row">
              <div class="col-12" id="uddStatus">
                Forventer du at blive senere færdig med din uddannelse på grund af fødsel eller adoption?
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    role="radio"
                    name="uddStatus"
                    id="uddStatusJa"
                    value="Ja"
                    formControlName="uddStatus"
                    (keydown.enter)="$event.preventDefault()"
                  />
                  <label class="form-check-label" for="uddStatusJa">Ja</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    role="radio"
                    name="uddStatus"
                    id="uddStatusNej"
                    value="Nej"
                    formControlName="uddStatus"
                    (keydown.enter)="$event.preventDefault()"
                  />
                  <label class="form-check-label" for="uddStatusNej">Nej</label>
                </div>
              </div>
              <div *ngIf="form.controls.uddStatus.touched && form.controls.uddStatus.errors" class="col-12 text-danger"
                role="alert" aria-atomic="true" #error1>
                Spørgsmål om studieaktivitet skal besvares
              </div>
            </div>
          </div>

          <div *ngIf="studieaktivVis" class="col-12">
            <div [@foldudAmination]="studieaktivAnimation">
              <div class="row">
                <!-- <div class="col-12" role="radiogroup" aria-labelledby="studieaktiv">
                  <div class="row">
                    <div class="col-12" id="studieaktiv">
                      Planlægger du at være studieaktiv, mens du modtager fødsels-SU?
                    </div>
                    <div class="col-12">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" role="radio" name="studieaktiv" id="studieaktivJa"
                          value="Ja" formControlName="studieaktiv" (keydown.enter)="$event.preventDefault()">
                        <label class="form-check-label" for="studieaktivJa">Ja</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" role="radio" name="studieaktiv" id="studieaktivNej"
                          value="Nej" formControlName="studieaktiv" (keydown.enter)="$event.preventDefault()">
                        <label class="form-check-label" for="studieaktivNej">Nej</label>
                      </div>
                    </div>
                    <div *ngIf="form.controls.studieaktiv.touched && form.errors && form.errors.studieaktiv && form.errors.studieaktiv.required"
                      class="col-12 text-danger" role="alert" aria-atomic="true" #error2>
                      
                    </div>
                  </div>
                </div> -->

                <div *ngIf="studieaktiv2Vis" class="col-12 mb-3">
                  <label for="studieaktivBeskrivelse">
                    Hvis du ikke planlægger at være studieaktiv, kan det have
                    indflydelse på, hvordan du får din fødsels-støtte udbetalt.
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="studieforloeb2Vis" class="col-12">
            <div [@foldudAmination]="studieforloebAnimation">
              <div class="row">
                <div class="col-12 mb-3 pt-3">
                  <label for="studieforloebJa">
                    Hvis du forventer at blive senere færdig med din uddannelse på grund af fødsel eller adoption, skal
                    du kontakte vejledningen på dit uddannelsessted, som kan hjælpe dig med at planlægge dit
                    nye studieforløb og vejlede dig i, hvordan du opfylder kravet om studieaktivitet ifølge SU-reglerne.
                    Forsinkelsen kan for eksempel skyldes ændrede praktikperioder eller udskydelse af fag.
                    <br /><br />
                    Du kan kontakte dit uddannelsessted, efter du har sendt denne ansøgning.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-accordion>
  </div>
</form>
