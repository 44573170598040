import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, timer,Observable } from 'rxjs';
import { daLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { mergeMap } from 'rxjs/operators';
import { AccordionStatus } from '../../../shared/ui/accordion/accordion-status/accordion-status.enum';
import { ApiAnsoegning } from '../api-ansoegning';
import { HentAnsoegningService } from '../../../core/hentAnsoegning.service';

@Component({
  selector: 'app-sekvensnr',
  templateUrl: './sekvensnr.component.html',
  styleUrls: ['./sekvensnr.component.scss']
})
export class SekvensnrComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Output() naesteTrinOutput$ = new EventEmitter<string>(); 
  @Input() naesteTrinInput$: Observable<string>;

  private apiAnsoegning: ApiAnsoegning;
  status: AccordionStatus;
  statusTekst: string;
  sekvensnrNuv?: string;
  sekvensnrKom?: string;
  sekvensnr?: string;
  sekvensnrJaNej?: string;
  instnavnNuv?: string;
  instnavnKom?: string;
  retningnavnNuv?: string;
  retningnavnKom?: string;
  visSekvensnr: boolean = false;

  @ViewChild('error', { static: false }) private errorElement: ElementRef;
  private subscriptions: Subscription[] = [];

  constructor(
    private localeService: BsLocaleService,
    private hentAnsoegningService: HentAnsoegningService
  ) {
    daLocale.invalidDate = 'Forkert dato format';
    defineLocale('dansk locale', daLocale);
    this.localeService.use('dansk locale');  
  }

  ngOnInit() {
    this.subscriptions.push(
      this.hentAnsoegningService
        .hentHentAnsoegning()
        .pipe(mergeMap(() => this.hentAnsoegningService.hentAnsoegning$))
        .subscribe((hentAnsoegning) => {
          this.sekvensnr = hentAnsoegning ? hentAnsoegning.sekvensnr : null;
          this.sekvensnrNuv = hentAnsoegning ? hentAnsoegning.sekvensnrNuv : null;
          this.sekvensnrKom = hentAnsoegning ? hentAnsoegning.sekvensnrKom : null;
          this.instnavnNuv = hentAnsoegning ? hentAnsoegning.instnavnNuv : null;
          this.instnavnKom = hentAnsoegning ? hentAnsoegning.instnavnKom : null;
          this.retningnavnNuv = hentAnsoegning ? hentAnsoegning.retningnavnNuv : null;
          this.retningnavnKom = hentAnsoegning ? hentAnsoegning.retningnavnKom : null;    
          this.form.patchValue({
            sekvensnrKom: this.sekvensnrKom    
          });
        })
    );

    // sæt status og border color
    this.status = AccordionStatus.SkalUdfyldes;
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      s.unsubscribe();
    }
  }
  public vaelgCheckBox(values: any) {
      this.status = AccordionStatus.Ok;
  }

  saetVisSekvensnr(visSekvensnr: boolean): void {
    timer(0).subscribe(() => {
      this.visSekvensnr = visSekvensnr;
    });
  }
}