export class ApiUdbetaling {
    public nu: Date;
    public fra: number; // unix timestamp med millisekunder
    public forrigeStoetteAar?;
    public naesteStoetteAar?;
    public restklip?;
    public restklipFoedsel?;
    public fravalgPer?;
    public inaktiv;
    public periodeAaben;
    public ratestatusPre;
    public ratestatusPost:String[];
    public ratestatusOk;
    public mmTekst;
    public surateKr;
    public foedselsrateKr;
    constructor(json?: any) {
        if (!json) { return; }
        this.forrigeStoetteAar = json.forrigeStoetteAar;
        this.naesteStoetteAar = json.naesteStoetteAar;
        this.restklip = json.restklip;
        this.restklipFoedsel = json.restklipFoedsel;
        this.fravalgPer = json.fravalgPer;
        this.inaktiv = json.inaktiv;
        this.periodeAaben = json.periodeAaben;
        this.ratestatusPre = json.ratestatusPre;
        this.ratestatusPost = json.ratestatusPost;
        this.ratestatusOk = json.ratestatusOk;
        this.mmTekst = json.mmTekst;
        this.surateKr = json.surateKr;
        this.foedselsrateKr = json.foedselsrateKr;
     }
}