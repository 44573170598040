<head>
<style>
 @media screen and (min-width: 500px) {
  .pc {
  }
}
 @media screen and (max-width: 500px) {
  .pc {
    display: none !important;
  }
}
 @media screen and (max-width: 500px) {
  .mobil {
  }
}
 @media screen and (min-width: 500px) {
  .mobil {
    display: none !important;
  }
  }
</style>
</head>
<form [formGroup]="formUdbetalingMaaned">
<div class="pc">
<div class="row"   style="border-top: 1px solid black">
    <div class="col pt-2 pb-2"  style="text-transform: capitalize" >
         {{ maaned }}
    </div>
    <div class="col pt-2 pb-2" style="text-align: center">
         <div *ngIf="periodeAaben === '+'"> 
            <select style="width: 236px" id="" formControlName="udbetalingsType">
                <option *ngFor="let maaned of maaneder" [value]="maaned.ratestatus">
                    {{ maaned.ratestatusTekst }}
                  </option>
            </select>
        </div> 

        <div *ngIf="periodeAaben === '-'"> 
            <div *ngIf="udbetaltFoedsel[maanedsNr] === '1' || udbetaltFoedsel[maanedsNr] === '2'"> 
                    Udbetalt
            </div>
        </div> 
    </div>
    <div class="col pt-2 pb-2" style="background-color: rgb(196, 184, 184);text-align: center">
         <div *ngIf="periodeAaben === '+'">
             {{ formUdbetalingMaaned.controls.foedselsKlip.value }}
        </div> 

        <div *ngIf="periodeAaben === '-'">
            {{ udbetaltFoedsel[maanedsNr] }}
       </div> 
    </div>
</div>
</div>

<div class="mobil">
<div>
    <div class=""   style="border-top: 1px solid black">
        <div class=" "  style="text-transform: capitalize" >
             {{ maaned }}
        </div>
        <div class="pt-2 pb-2" style="text-align: center">
             <div *ngIf="periodeAaben === '+'"> 
                <select style="width: 236px" id="" formControlName="udbetalingsType">
                    <option *ngFor="let maaned of maaneder" [value]="maaned.ratestatus">
                        {{ maaned.ratestatusTekst }}
                      </option>
                </select>
            </div> 
    
            <div *ngIf="periodeAaben === '-'"> 
                <div *ngIf="udbetaltFoedsel[maanedsNr] === '1' || udbetaltFoedsel[maanedsNr] === '2'"> 
                        Udbetalt
                </div>
            </div> 
        </div>
        <div class=" pt-2 pb-2" style="text-align: center">
             <div *ngIf="periodeAaben === '+'">
                Forbrug af fødsels-støtte: <span class="fw-bold">{{ formUdbetalingMaaned.controls.foedselsKlip.value }}</span>
            </div> 
    
            <div class="row" style="display: flex;justify-content: center" *ngIf="periodeAaben === '-'">
               <div  *ngIf="udbetaltFoedsel[maanedsNr] === '1' || udbetaltFoedsel[maanedsNr] === '2'"> 
                Forbrug af fødsels-støtte: &nbsp;
               </div> 
               <div class="fw-bold">{{ udbetaltFoedsel[maanedsNr] }}</div>
           </div> 
        </div>
    </div>
</div>
</div>
</form> 
