<form [formGroup]="form">
  <div class="row">
    <app-accordion
      class="col-12"
      [ariaLabel]="'Åben sektion med funktionsnedsættelser. Her skal du angive, hvilken type varige funktionsnedsættelse(r) du har'"
      [h]="2"
      [overskrift]="'Er du enlig forsørger'"
      [status]="status"
      [statusTekst]="statusTekst"
      [userTrackingUrl]="'/ansoegning/enligforaeldre'"
      [vis]="visenligforaeldre"
      [scrollIntoView]="'smart'"
      (vis$)="aabnedeLukkedeenligforaeldre($event)"
      #enligforaeldreAccordion
    >
      <div class="row col-12" (keydown.enter)="$event.preventDefault()">
        <div class="col-12 pt-2 pb-3">
          Du er enlig forsørger, hvis du enten:
          <ul>
            <br />
            1. er berettiget til ekstra børnetilskud fra Udbetaling Danmark, eller
            <br />
            2. er enlig forsørger samtidig med, at du:<br />
            - har et barn anbragt uden for hjemmet, eller<br />
            - ikke er dansk statsborger, eller<br />
            - bor i udlandet
          </ul>
          <br />
          <span>Som enlig kan du få op til 12 måneders fødsels-støtte.</span>
          <br /><br />

          <span>Hvis du allerede får forsørgertillæg, kan du ikke modtage det samtidig med fødsels-støtten. Når du har gennemført ansøgningen om fødsels-støtte, annullerer vi automatisk dit forsørgertillæg.</span>
          <br />
          <br />
          <span>Du kan <strong>ikke </strong>overføre eller modtage fødsels-støtte fra en anden forælder.</span>
        </div>

        <div class="col-12" #topElement></div>
        <div class="form-check">
          <div class="col-12" (keydown.enter)="$event.preventDefault()">
            <input class="form-check-input" (click)="vaelgCheckBox($event)" formControlName="enlig"
              name="enlig" type="checkbox" value="checked" id="flexCheckDefault" />
            <label class="form-check-label">
              Jeg søger fødsels-støtte som enlig forsørger
            </label>
          </div>
        </div>
      </div>
      <br />
      <div *ngIf="enligJa" class="col-12 mt-4">
        Som enlig forsørger bliver du først godkendt til op til 9 måneders fødsels-støtte. 
        <br /><br />
        Du bliver godkendt til yderligere op til 3 måneders støtte, hvis vi efter fødslen får besked om, at du er berettiget til ekstra børnetilskud.
        <!-- Hvis du er omfattet af en betingelse i punkt 2, kan vi ikke hente oplysningerne automatisk. Vi sender dig derfor en besked herom via Digital Post. 
         -->
        <br /><br />
      </div>
    </app-accordion>
  </div>
</form>
