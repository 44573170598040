import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, filter, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';
import { DatoService } from '../shared/dato/dato.service';
import { Udbetaling } from './udbetaling';

@Injectable({
  providedIn: 'root'
})
export class UdbetalingsService2 {

  public udbetaling$: BehaviorSubject<Udbetaling> = new BehaviorSubject<Udbetaling>(null);
  private apiRequestIgang$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private backendDato: Date;

  constructor(
    private datoService: DatoService,
    private http: HttpClient,
    private router: Router
  ) {}

  hentUdbetaling(): Observable<boolean> {
    // if(this.udbetaling$.value){
    //   return of(true);
    // }
    if (this.apiRequestIgang$.value) {
      return this.apiRequestIgang$.pipe(
        filter((igang) => !igang),
        first(),
        map(() => true)
      );
    }
    this.apiRequestIgang$.next(true);
    return this.http.get<Udbetaling>('/api/udbetaling').pipe(
        tap((udbetaling) => {
          this.udbetaling$.next(udbetaling);
          this.apiRequestIgang$.next(false);
        }),
        map(() => true)
      );
  }

  erBackendDatoSat(): boolean {
    return this.backendDato ? true : false;
  }

  dagsdato(): Date {
    if (this.erBackendDatoSat()) {
      return this.backendDato;
    } else {
      return this.datoService.konverterTilUTCDato(moment.utc().toDate());
    }
  }

  nu(): Date {
    if (this.erBackendDatoSat()) {
      const nu: Date = moment.utc().toDate();
      const dagsdato: Date = this.datoService.konverterTilUTCDato(nu);
      const diff: number = moment.utc(nu).diff(moment.utc(dagsdato));
      return moment.utc(this.backendDato).add(diff, 'milliseconds').toDate();
    } else {
      return moment.utc().toDate();
    }
  }

}
