import { Component, Input  } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-godkendelse',
  templateUrl: './godkendelse.component.html',
  styleUrls: ['./godkendelse.component.scss']
})
export class GodkendelseComponent {

  @Input() kvitteringData: any;

  constructor(private router: Router) { }

  gaaTilForside(): void {
    this.router.navigate(['/'])
      .catch((error) => {
        console.log('forside: navigate til /forside fejlede. error=', error);
      });
  }

  gaaTilUdbetaling(): void {
    this.router.navigate(['/udbetaling'])
      .catch((error) => {
        console.log('udbetaling: navigate til /udbetaling fejlede. error=', error);
      });
  }
}