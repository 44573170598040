import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, filter, first } from 'rxjs/operators';
import { ValidationErrors } from '@angular/forms';
import * as moment from 'moment/moment';
import { DatoService } from '../shared/dato/dato.service';
import { Profil } from './profil';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  public profil$: BehaviorSubject<Profil> = new BehaviorSubject<Profil>(null);
  private apiRequestIgang$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private backendDato: Date;

  constructor(private datoService: DatoService , private http: HttpClient) {
    // reload profil når fuldmagtbruger ændres
  }

  hentProfil(): Observable<boolean> {
    // if(this.profil$.value){
    //   return of(true);
    // }
    if (this.apiRequestIgang$.value) {
      return this.apiRequestIgang$.pipe(
        filter((igang) => !igang),
        first(),
        map(() => true)
      );
    }
    this.apiRequestIgang$.next(true);
    return this.http.get<Profil>('/api/profil').pipe(
        tap((profil) => {
          this.profil$.next(profil);
          this.apiRequestIgang$.next(false);
        }),
        map(() => true)
      );
  }

  erBackendDatoSat(): boolean {
    return this.backendDato ? true : false;
  }

  dagsdato(): Date {
    if (this.erBackendDatoSat()) {
      return this.backendDato;
    } else {
      return this.datoService.konverterTilUTCDato(moment.utc().toDate());
    }
  }

  nu(): Date {
    if (this.erBackendDatoSat()) {
      const nu: Date = moment.utc().toDate();
      const dagsdato: Date = this.datoService.konverterTilUTCDato(nu);
      const diff: number = moment.utc(nu).diff(moment.utc(dagsdato));
      return moment.utc(this.backendDato).add(diff, 'milliseconds').toDate();
    } else {
      return moment.utc().toDate();
    }
  }

  private ansoegningIkkeMulig(profil: Profil): ValidationErrors {
    if (!profil) {
      return {tomProfil: true};
    }
    
    if (profil.ansoegningOprettet) {
      return {alleredeAnsoegt: profil.ansoegningOprettet};
    }   
    return;
  }

  erAnsoegningIkkeMulig(): Observable<ValidationErrors> {
    if (!this.profil$.value) {
      this.hentProfil().subscribe();
    }
    return this.profil$.pipe(map((profil) => this.ansoegningIkkeMulig(profil)));
  }

}
