import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntypedFormGroup, Validators, ValidationErrors, UntypedFormBuilder, ValidatorFn } from '@angular/forms';

import { ApiAnsoegning, ApiAnsoegningStatus } from './api-ansoegning';
import { HentAnsoegningService } from '../../core/hentAnsoegning.service';
import { ProfilService } from '../../core/profil.service';

const ValiderStudieforloeb: ValidatorFn = (fg: UntypedFormGroup) => {
  if (!fg || !fg.parent) {
    return null;
  }
  const uddStatus: string = fg.controls.uddStatus.value;
  const studieaktiv: string = fg.controls.studieaktiv.value;

  if (uddStatus === 'Ja') {
    return;
  } 
  if (uddStatus === 'Nej') {
    if (studieaktiv === 'Nej') {
      return;
    } else if (studieaktiv === 'Ja') {
      return;
    } else {
      return ;
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class AnsoegningService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private profilService: ProfilService,
    private hentAnsoegningService: HentAnsoegningService
  ) { }

  sekvensnrNuv?;
  sekvensnrKom?;
  kvitteringData?;
  public apiAnsoegning: ApiAnsoegning;
  subscriptions: Subscription[] = [];

  initialiserAnsoegningFormGroup() {
    let uddType: string = this.profilService.profil$.value.uddType;
    let sekvensnrKom2: String = this.hentAnsoegningService.hentAnsoegning$.value.sekvensnrKom;

    if(sekvensnrKom2 == '00'){
    if (uddType == 'VU') {
      return this.formBuilder.group({
        sekvensnrNuv: [null],
        sekvensnrKom: [null],
        sekvensnr: [null],
        termin: [null],
        fodseldt: [null],
        valgFodsel: [null],
        barn: [null],
        studieforloeb: this.formBuilder.group({
          uddStatus: [null, Validators.required],
          studieaktiv: [null]
        }, { validator: ValiderStudieforloeb }),
        enlig: [null],
        samtykke: [null, Validators.requiredTrue]
      });
    } else {
      return this.formBuilder.group({
        sekvensnrNuv: [null],
        sekvensnrKom: [null],
        sekvensnr: [null],
        termin: [null],
        fodseldt: [null],
        valgFodsel: [null],
        barn: [null],
        studieforloeb: this.formBuilder.group({
          uddStatus: [null, Validators.required],
          studieaktiv: [null],
        }),
        enlig: [null],
        samtykke: [null, Validators.requiredTrue]
      });
    }
  } else {
    if (uddType == 'VU') {
      return this.formBuilder.group({
        sekvensnrNuv: [null],
        sekvensnrKom: [null],
        sekvensnr: [null, Validators.required],
        termin: [null],
        fodseldt: [null],
        valgFodsel: [null],
        barn: [null],
        studieforloeb: this.formBuilder.group({
          uddStatus: [null, Validators.required],
          studieaktiv: [null]
        }, { validator: ValiderStudieforloeb }),
        enlig: [null],
        samtykke: [null, Validators.requiredTrue]
      });
    } else {
      return this.formBuilder.group({
        sekvensnrNuv: [null],
        sekvensnrKom: [null],
        sekvensnr: [null, Validators.required],
        termin: [null],
        fodseldt: [null],
        valgFodsel: [null],
        barn: [null],
        studieforloeb: this.formBuilder.group({
          uddStatus: [null, Validators.required],
          studieaktiv: [null],
        }),
        enlig: [null],
        samtykke: [null, Validators.requiredTrue]
      });
    }
  }
}

  erAnsoegningIkkeMulig(): Observable<ValidationErrors> {
    return this.profilService.erAnsoegningIkkeMulig();
  }

  initialiserFormData(form: UntypedFormGroup): FormData {
    const formData = new FormData();
    formData.append('ansoegning', new Blob([JSON.stringify(this.apiAnsoegning)], { type: 'application/json' }));
    return formData;
  }

  sendAnsoegning(formgroup: UntypedFormGroup, ansogeningData: any): Observable<any> {
    let enligJaNej = '';

    if (formgroup.value.enlig) {
      enligJaNej = 'J';
    } else {
      enligJaNej = 'N';
    }

    this.apiAnsoegning = new ApiAnsoegning();
    const json: any = formgroup.value;

    if (json.fodseldt == null) {
      this.apiAnsoegning.termin = json.termin;
    } else {
      this.apiAnsoegning.termin = json.fodseldt;
    }  
    if(json.valgFodsel == null) {
      this.apiAnsoegning.valgFodsel = '0';
    } else {
      this.apiAnsoegning.valgFodsel = json.valgFodsel;
    } 

    this.apiAnsoegning.enlig = enligJaNej;
    this.apiAnsoegning.sekvensnr = json.sekvensnr;
    this.apiAnsoegning.sekvensnrNuv = ansogeningData.sekvensnrNuv;
    this.apiAnsoegning.sekvensnrKom = ansogeningData.sekvensnrKom;
    this.apiAnsoegning.instnavnNuv= ansogeningData.instnavnNuv;
    this.apiAnsoegning.instnavnKom = ansogeningData.instnavnKom;
    this.apiAnsoegning.uddBopal = ansogeningData.uddBopal;
    this.apiAnsoegning.retningnavnNuv = ansogeningData.retningnavnNuv;
    this.apiAnsoegning.retningnavnKom = ansogeningData.retningnavnKom;
    this.apiAnsoegning.uddstart = ansogeningData.uddstart;
    this.apiAnsoegning.barn = ansogeningData.barn;
    this.apiAnsoegning.fodseldt = ansogeningData.fodseldt;
    this.apiAnsoegning.valgtBarn = json.barn;
    return this.http
      .post<ApiAnsoegning>('/api/ansoegning', this.apiAnsoegning, { headers: { NoInterceptorTimeout: 'x' } })
      .pipe(
        tap((res: any) => { this.kvitteringData = res; })
      );
  }

  public hentSendtAnsoegning(): ApiAnsoegning {
    return this.apiAnsoegning;
  }

  public hentTidligereIndsendteAnsoegninger(): Observable<ApiAnsoegningStatus[]> {
    return this.http.get<ApiAnsoegningStatus[]>('/api/ansoegning');
  }

  public hentTidligereIndsendtAnsoegning(ansoegningStatus: ApiAnsoegningStatus): Observable<any> {
    return this.http.get<any>('/api/ansoegning/' + ansoegningStatus.id);
  }
}