import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Router } from '@angular/router';

import { ProfilService } from '../../core/profil.service';
import { HentAnsoegningService } from '../../core/hentAnsoegning.service';
import { AccordionStatus } from '../../shared/ui/accordion/accordion-status/accordion-status.enum';

@Component({
  selector: 'app-overblik',
  templateUrl: './overblik.component.html',
  styleUrls: ['./overblik.component.scss']
})
export class OverblikComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() naesteTrinInput$: Observable<string>;
  @Output() naesteTrinOutput$ = new EventEmitter<string>();

  status: AccordionStatus;
  statusTekst: string;
  userTrackingUrl: string;
  arbejdsevneVis: boolean = false;
  vuVis: boolean = false;
  uuVis: boolean = false;

  vis: boolean = true;
  @ViewChild('topElement', { static: true }) topElement: ElementRef;
  @ViewChild('bottomElement', { static: false }) bottomElement: ElementRef;
  studiejobAntalTimerVis: boolean;
  stoppetArbejdeAarsagVis: boolean;
  subscriptions: Subscription[] = [];
  studiejob: boolean = null;
  taeller: { [key: string]: string } = {};
  terminsMaaned?;
  enlig?: string;
  barn?: string[];
  fodseldt?: string[];
  uverificeretFodsel?: string;
  kvitteringsside?: string;
  uddstart?: string;
  tildeltKlip?: string;
  studerende?: string;
  aktivFoedsel?: string;
  uddType?: string;
  termin?: string;
  cpr?: string;
  sekvensnrNuv?: string;
  sekvensnrKom?: string;
  sekvensnr?: string;
  instnavnNuv?: string;
  instnavnKom?: string;
  retningnavnNuv?: string;
  retningnavnKom?: string;
  studerendeTrueFalse: boolean = false;
  enligTrueFalse: boolean = false;
  overblikVis1: boolean = false;
  harFoedtBarn: boolean = false;
  enligVis: boolean = false;
  visAnsoegning: boolean = false;
  ansoegningVis: boolean = false;
  udbetalingVis: boolean = false;
  overfoerVis: boolean = false;
  studerendeVis: boolean = false;

  constructor(
    private router: Router,
    private profilService: ProfilService,
    private hentAnsoegningService: HentAnsoegningService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.hentAnsoegningService.hentAnsoegning$.subscribe((hentAnsoegning) => {
        this.sekvensnr = hentAnsoegning ? hentAnsoegning.sekvensnr : null;
        this.sekvensnrNuv = hentAnsoegning ? hentAnsoegning.sekvensnrNuv : null;
        this.sekvensnrKom = hentAnsoegning ? hentAnsoegning.sekvensnrKom : null;
        this.instnavnNuv = hentAnsoegning ? hentAnsoegning.instnavnNuv : null;
        this.instnavnKom = hentAnsoegning ? hentAnsoegning.instnavnKom : null;
        this.retningnavnNuv = hentAnsoegning ? hentAnsoegning.retningnavnNuv : null;
        this.retningnavnKom = hentAnsoegning ? hentAnsoegning.retningnavnKom : null;
        this.barn = hentAnsoegning ? hentAnsoegning.barn : null;
        this.fodseldt = hentAnsoegning ? hentAnsoegning.fodseldt : null;
        this.kvitteringsside = hentAnsoegning ? hentAnsoegning.kvitteringsside : null;
        this.uddstart = hentAnsoegning ? hentAnsoegning.uddstart : null;
        this.tildeltKlip = hentAnsoegning ? hentAnsoegning.tildeltKlip : null;
      })
    );

    this.subscriptions.push(
      this.profilService.profil$.subscribe((profil) => {
        this.studerende = profil ? profil.studerende : null;
        this.aktivFoedsel = profil ? profil.aktivFoedsel : null;
        this.enlig = profil ? profil.enlig : null;
        this.uddType = profil ? profil.uddType : null;
        this.termin = profil ? profil.termin : null;
        this.cpr = profil ? profil.cpr : null;
        this.uverificeretFodsel = profil ? profil.uverificeretFodsel : null;
        // sæt default værdier. profil$ vil svare flere gange ved skift af agerende fuldmagtsbruger
        this.studerendeVis = false;
        this.ansoegningVis = false;
        this.udbetalingVis = false;
        this.overfoerVis = false;
        if (this.studerende == '') {
          this.studerendeVis = true;
        }

        if (this.studerende == 'Ja') {
          if (this.uverificeretFodsel == 'Nej' && this.aktivFoedsel == 'Ja') {
            this.ansoegningVis = true;
            this.udbetalingVis = true;
            this.overfoerVis = true;
          }

          if (this.uverificeretFodsel == 'Ja') {
            this.udbetalingVis = true;
          }

          if (this.uverificeretFodsel == 'Nej' && this.aktivFoedsel == '') {
            this.ansoegningVis = true;
          }
        }
      })
    );
  }

  startUdbetaling(): void {
    this.router.navigate(['/udbetaling'])
      .catch((error) => {
        console.log('forside: navigate til /ansoegning fejlede. error=', error);
      });
  }

  startAnsoegning(): void {
    this.router.navigate(['/ansoegning'])
      .catch((error) => {
        console.log('forside: navigate til /ansoegning fejlede. error=', error);
      });
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  vuHarmonika(vis: boolean): void {
    this.vuVis = vis;
  }

  uuHarmonika(vis: boolean): void {
    this.uuVis = vis;
  }
}