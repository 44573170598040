<head>
   <meta charset="utf-8" />
</head>
<form [formGroup]="form">
   <div class="row">
      <app-accordion
         class="col-12"
         [ariaLabel]="'Åben sektion med startmåned  for handicaptillægsansøgning'"
         [h]="2"
         [overskrift]="'Termins- eller fødselsdato'"
         [status]="status"
         [userTrackingUrl]="'/ansoegning/termin'"
         [vis]="visTermin"
         (vis$)="saetVisTermin($event)"
      >
         <div class="col-12">
            <div *ngIf="knapDiv">
               <p>Hvilket barn vil du søge fødsels-støtte til? Er dit barn ikke på listen, vælg "Kommende barn" og tast fødselsdato.</p>
               <button type="button" id="knap2" (click)="div2Function();" class="btn btn-primary me-2">Kommende barn</button>
               <button type="button" id="knap1" (click)="div1Function();" class="btn btn-primary ms-2">Født barn</button>
               <br /><br />
            </div>

            <div *ngIf="div1">
               <div class=" pt-2 pb-2">
                  <p>Vælg barnet fra listen:</p>
                  <select (change)="onChange($event.target.value)" id='termin' name='termin' formControlName="termin">
                     <option *ngFor="let barn of barn" [value]="barn">
                        {{ barn }}
                     </option>
                  </select>
               </div>
            </div>

            <div *ngIf="div2">
               <div class="row">
                  <div class="col-12 pt-2 pb-2">
                     <p><b>Som mor </b>kan du tidligst søge om fødsels-støtte tre måneder før din terminsmåned og få den udbetalt
                        to måneder før terminsmåneden.
                     </p>
                     <p><b>Som far eller medmor </b> kan du tidligst søge om fødsels-støtte én måned før terminsmåneden og få den
                        udbetalt fra terminsmåneden.
                     </p>
                  </div>
               </div>
               <label for="termin">* Indtast terminsdato (tastes med bindestreg: DD-MM-YYYY)</label>
               <input
                  id="termin2"
                  name="termin"
                  type="text"
                  maxlength="10"
                  placeholder="DD-MM-YYYY"
                  required
                  class="form-control"
                  formControlName="termin"
                  bsDatepicker
                  [bsConfig]="{ adaptivePosition: true }"
                  (onHidden)="visDatoVaelger($event)"
                  (onShown)="visDatoVaelger($event)"
                  (change)="visDatoVaelger($event)"
                  (keydown.enter)="tjekTermin($event)"
                  (keydown.tab)="lukDatoVaelger()"
                  (keydown.shift.tab)="lukDatoVaelger()"
                  #termin
               />
               <div *ngIf="form.controls.termin.touched && form.controls.termin.errors" class="invalid-feedback"
                  role="alert" aria-atomic="true" #error>
                  <span *ngIf="form.controls.termin.errors.required">
                     Terminsdato skal vælges.
                  </span>
                  <span *ngIf="form.controls.termin.errors.bsDate || form.controls.termin.errors.maanedEjMulig"
                     id="terminFejl">
                     Denne terminsdato kan ikke vælges.
                  </span>
               </div>
            </div>
         </div>
      </app-accordion>
   </div>
</form>
