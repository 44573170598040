import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, OnDestroy} from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { interval, Subscription, timer } from 'rxjs';
import { daLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { mergeMap } from 'rxjs/operators';
import { AccordionStatus } from '../../../shared/ui/accordion/accordion-status/accordion-status.enum';
import { HentAnsoegningService } from '../../../core/hentAnsoegning.service';
import { AnsoegningService } from '../ansoegning.service';
import { DatoService } from '../../../shared/dato/dato.service';
import { ProfilService } from '../../../core/profil.service';
import { BsDatepickerContainerComponent } from 'ngx-bootstrap/datepicker/themes/bs/bs-datepicker-container.component';

@Component({
  selector: 'app-termin',
  templateUrl: './termin.component.html',
  styleUrls: ['./termin.component.scss']
})
export class TerminComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Output() naesteTrinOutput$ = new EventEmitter<string>();

  private terminControl: AbstractControl;
  status: AccordionStatus;
  statusTekst: string;
  visTermin: boolean = false;
  foedtBarn?;
  sekvensnrNuv?;
  sekvensnrKom?;
  sekvensnr?;
  instnavnNuv?;
  instnavnKom?;
  retningnavnNuv?;
  retningnavnKom?;
  barn?;
  fodseldt?;
  valgFodsel?;
  foedtBart2: string;
  hideBarn: boolean = true;
  div1: boolean = false;
  div2: boolean = false;
  knapDiv: boolean = true;
  terminDataString: String;

  @ViewChild('termin', { static: true }) private terminElement: ElementRef;
  visesDatovaelger: boolean;
  @ViewChild('error', { static: false }) private errorElement: ElementRef;
  private subscriptions: Subscription[] = [];

  constructor(
    private localeService: BsLocaleService,
    private profilService: ProfilService,
    private ansoegningService: AnsoegningService,
    private datoService: DatoService,
    private hentAnsoegningService: HentAnsoegningService
  ) {
    daLocale.invalidDate = 'Forkert dato format';
    defineLocale('dansk locale', daLocale);
    this.localeService.use('dansk locale');
  }

  ngOnInit() {
    this.subscriptions.push(
      this.hentAnsoegningService
        .hentHentAnsoegning()
        .pipe(mergeMap(() => this.hentAnsoegningService.hentAnsoegning$))
        .subscribe((hentAnsoegning) => {
          this.sekvensnr = hentAnsoegning ? hentAnsoegning.sekvensnr : null;
          this.sekvensnrNuv = hentAnsoegning ? hentAnsoegning.sekvensnrNuv : null;
          this.sekvensnrKom = hentAnsoegning ? hentAnsoegning.sekvensnrKom : null;
          this.instnavnNuv = hentAnsoegning ? hentAnsoegning.instnavnNuv : null;
          this.instnavnKom = hentAnsoegning ? hentAnsoegning.instnavnKom : null;
          this.retningnavnNuv = hentAnsoegning ? hentAnsoegning.retningnavnNuv : null;
          this.retningnavnKom = hentAnsoegning ? hentAnsoegning.retningnavnKom : null;
          this.valgFodsel = hentAnsoegning ? hentAnsoegning.valgFodsel : null;
          this.fodseldt = (hentAnsoegning ? hentAnsoegning.fodseldt : null).filter((element) => {
            return element !== '';
          });
          this.barn = (hentAnsoegning ? hentAnsoegning.barn : null).filter((element) => {
            return element !== '';
          });
          this.barn.forEach((element, index) => {
            this.barn[index] = this.barn[index].slice(0, 2) + '-' + this.barn[index].slice(2, 4) + '-20' + this.barn[index].slice(4, 6);
          });
          if (this.barn.length < 1) {
            this.knapDiv = false;
            this.div2=true;
            this.div1=false;
        }
        })
    );
    this.terminControl = this.form.controls['termin'];
    // sæt status og border color
    this.status = AccordionStatus.SkalUdfyldes;
    this.subscriptions.push(
      this.terminControl.statusChanges.subscribe((status) => {
        if (status === 'INVALID') {
          this.status = AccordionStatus.Fejl;
        } else {
          this.status = AccordionStatus.Ok;
        }
        if (this.status === AccordionStatus.Fejl) {
        } else {
          // startMaaned klar.
        }
      })
    );
    this.subscriptions.push(
      interval(100).subscribe(() => {
        if (this.errorElement) {
          this.status = AccordionStatus.Fejl;
          this.statusTekst = this.errorElement.nativeElement.innerText;
        } else if (!this.terminControl.value) {
          this.status = AccordionStatus.SkalUdfyldes;
          this.statusTekst = null;
        }
      })
    );
  }

  onChange(valgteBarn) {
    let barnIndex = this.barn.indexOf(valgteBarn);
    let valgFodselNum = Number(barnIndex);
    valgFodselNum+=1;
    this.valgFodsel = valgFodselNum;
    this.form.controls.valgFodsel.setValue(this.valgFodsel);
    this.form.controls.barn.setValue(valgteBarn);
    this.foedtBarn = this.fodseldt[barnIndex];
    this.form.controls.fodseldt.setValue(this.foedtBarn);
  }
 
  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      s.unsubscribe();
    }
  }

  tjekTermin(event: Event): boolean {
    this.terminElement.nativeElement.blur();
    this.terminControl.updateValueAndValidity();
    this.terminElement.nativeElement.focus();
    if (this.terminControl.valid) {
      this.status = AccordionStatus.Ok;
    } else if (!this.terminControl.value) {
      this.status = AccordionStatus.SkalUdfyldes;
    } else {
      this.status = AccordionStatus.Fejl;
    }
    event.preventDefault();
    if (this.status === AccordionStatus.Fejl) {
      if (this.visesDatovaelger) {
        console.warn('dato er invalid, men datovælger skjuler fejl meddelelsen!');
      }
    } else if (this.status === AccordionStatus.Ok) {
    }
    return false;
  }

  div1Function(){
    this.div1=true;
    this.div2=false;
    document.getElementById('knap1').style.backgroundColor =  'MediumPurple';
    document.getElementById('knap2').style.backgroundColor =  'DarkSlateBlue';
    this.form.controls['termin'].reset();
    }

  div2Function(){
    this.div2=true;
    this.div1=false;
    document.getElementById('knap2').style.backgroundColor =  'MediumPurple';
    document.getElementById('knap1').style.backgroundColor =  'DarkSlateBlue';
    this.form.controls['termin'].reset();
    this.form.controls['fodseldt'].reset();
  }

  saetVisTermin(visTermin: boolean): void {
    timer(0).subscribe(() => {
      this.visTermin = visTermin;
    });
  }

  visDatoVaelger(event: BsDatepickerContainerComponent): void {
    var terminById = (<HTMLInputElement>document.getElementById('termin2')).value;
    this.form.patchValue({
      termin: terminById
    });
    this.visesDatovaelger = !!event;
  }

  untouchEventMethos() {
    var terminById = (<HTMLInputElement>document.getElementById('termin2')).value;
    this.form.patchValue({
      termin: terminById
    });
  }
  
  lukDatoVaelger(): void {
    if (this.visDatoVaelger) {
      this.terminElement.nativeElement.click();
    }
  }

}
