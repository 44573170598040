    <div class="row">
  
      <div class="col-12 pb-4 text-center"> 
        <h1 >Kvittering</h1>
        <br />
        <div style="color: green;">
        Din udbetalingsplan er blevet godkendt.<br /><br /> Du modtager også afgørelsen som Digital Post via borger.dk
        </div>
        
      </div>
      
    </div>
    <br />
    <div>
      <div class="row col-12">
        <div class="col-12" >
          <h5>Kvittering</h5>
          Du har søgt om følgende udbetalingsplan:
        </div>
      </div>
  </div>

  <br />
  <br />
      <div class="row col-12 " >
        <div class="col-12">
           
            <div class= >
                <div class="row" style="border-top: 1px solid black;">
                  <div class="col pt-2 pb-2" >
                    {{ stoetteAar }}
                  </div>
                  <div class="col pt-2 pb-2" style="text-align: center;" >
                    UdbetalingsType
                  </div>
                  <div class="col pt-2 pb-2" style="background-color: rgb(196, 184, 184);text-align: center;">
                    Forbrug af fødsels-støtte
                  </div>
                </div>
                
                <div *ngFor="let maaned of maaneder" >
                    <div class="row"  style="border-top: 1px solid black;">
                        <div class="col pt-2 pb-2"  style="text-transform: capitalize;" >
                             {{ maaned.maanedsNavn }}
                        </div>
                        <div class="col pt-2 pb-2" style="text-align: center;">

                             <div *ngIf="maaned.periodeAaben === '+'"> 
                                      {{ maaned.udbetalingsTypeTekst }}
                            </div> 

                            <div *ngIf="maaned.periodeAaben === '-'">
                              <div *ngIf="maaned.foedselsKlip !== 0">
                              Udbetalt
                            </div>
                    </div> 
                            
                        </div>
                        <div class="col pt-2 pb-2" style="background-color: rgb(196, 184, 184);text-align: center;">
                          <div *ngIf="maaned.periodeAaben === '-'">
                            <div *ngIf="maaned.foedselsKlip !== 0">
                              {{ maaned.foedselsKlip }}
                          </div>
                  </div> 
                            <div *ngIf="maaned.periodeAaben === '+'">
                              {{ maaned.foedselsKlip }}
                            </div> 

                        </div>
                    </div>
                    </div>
                   
                    
                   
                
                  <div class="row text-center border border-1 border-dark">
                    Du kan tidligst udfylde din udbetalingsplan for {{ stoetteAarPlus1 }} den 6. december {{ stoetteAar }}. Når udbetalingsplanen (for {{ stoetteAarPlus1 }}) er klar, får du besked som Digital Post via borger.dk. 
                  </div>
              </div>
        </div>
        <div class="col-12 pt-3">
          <div class="pt-3">
            <button (click)="gaaTilForside()" type="submit" class="btn btn-primary btn-md d-block w-100">Tilbage til forsiden</button>
          </div>
        </div>
    </div>

