import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, Observable, timer } from 'rxjs';
import { AccordionComponent } from '../../../shared/ui/accordion/accordion.component';
import { AccordionStatus } from '../../../shared/ui/accordion/accordion-status/accordion-status.enum';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'app-samtykke',
  templateUrl: './samtykke.component.html',
  styleUrls: ['./samtykke.component.scss']
})
export class SamtykkeComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() naesteTrinInput$: Observable<string>;
  @Output() samtykke$ = new EventEmitter<boolean>();

  samtykkeVis = false;
  status: AccordionStatus = AccordionStatus.SkalUdfyldes;
  subscriptions: Subscription[] = [];
  @ViewChild('samtykkeAccordion', { static: true }) samtykkeAccordion: ElementRef;

  constructor(private userTrackingService: UfmUserTrackingService) { }

  ngOnInit() {
    this.form.controls.samtykke.valueChanges.subscribe((samtykke) => {
      if (samtykke) {
        this.userTrackingService.sendUrl('/forside/samtykke/ja');
        this.status = AccordionStatus.Ok;
      } else {
        this.userTrackingService.sendUrl('/forside/samtykke/nej');
        this.status = AccordionStatus.SkalUdfyldes;
      }
    });
    // fold personlig redegørelse sammen  + fold samtykke ud
    this.subscriptions.push(
      this.naesteTrinInput$.subscribe((naesteComponent) => {
        if (naesteComponent === 'SamtykkeComponent' && !this.samtykkeVis) {
          // fold ud
          this.samtykkeVis = true;
          timer(400).subscribe(() => {
            const samtykkeAccordion = this.samtykkeAccordion as any as AccordionComponent;
            samtykkeAccordion.accordionButton.nativeElement.focus();
          });
        }
      })
    );
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      s.unsubscribe();
    }
  }
}
