// sådan skal Fødels-støtte ansøgning se ud når den sendes til API end point

export class ApiAnsoegning {
    public enlig?: string;
    public termin?: string;
    public valgFodsel?: string;
    public sekvensnrNuv?: string;
    public sekvensnrKom?: string;
    public sekvensnr?: string;
    public instnavnNuv: string;
    public instnavnKom: string;
    public retningnavnNuv: string;
    public retningnavnKom: string;
    public uddBopal: string;
    public uddstart: string;
    public barn: string[];
    public fodseldt: string[];
    public valgtBarn: string;
    constructor(json?: any) {
        if (!json) { return; }
        //  this.nu = json.nu;
        // this.fra = json.fra;
        this.enlig = json.enligFoaeldre;
        this.termin = json.termin;
        this.valgFodsel = json.valgFodsel;
        this.sekvensnrNuv = json.sekvensnrNuv;
        this.sekvensnrKom = json.sekvensnrKom;
        this.sekvensnr = json.sekvensnr;
        this.instnavnNuv = json.instnavnNuv;
        this.instnavnKom = json.instnavnKom;
        this.retningnavnNuv = json.retningnavnNuv;
        this.retningnavnKom = json.retningnavnKom;
        this.uddBopal = json.uddbopal;
        this.uddstart = json.uddstart;
        this.barn = json.barn;
        this.fodseldt = json.fodseldt;
        this.valgtBarn = json.valgtBarn;
    }
}

export class ApiAnsoegningStatus {
    id: string;
    modtaget: number;
    nr: number;
    status: string;
}
