<div *ngIf="ok" class="float-end" [ngClass]="ngClass">
    <span class="pe-2">OK</span>
    <fa-icon [icon]="faCheckCircle"></fa-icon>
</div>
<div *ngIf="skalUdfyldes" class="float-end" [ngClass]="ngClass">
    <span class="pe-2">Skal udfyldes</span>
    <fa-icon [icon]="faExclamation"></fa-icon>
</div>
<div *ngIf="fejl" class="float-end" [ngClass]="ngClass">
    <span class="pe-2">Fejl</span>
    <fa-icon [icon]="faTimesCircle"></fa-icon>
</div>