import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { timer, interval, Subscription } from 'rxjs';

import { AccordionStatus } from './accordion-status/accordion-status.enum';
import { foldudAmination } from '../../animationer/animationer';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

const foldud = 'foldud';
const foldsammen = 'foldsammen';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [foldudAmination]
})
export class AccordionComponent implements OnInit, OnChanges {

  @Input() overskrift: string;
  @Input() status: AccordionStatus;
  @Input() statusTekst: string;
  @Input() ariaLabel: string;
  @Input() userTrackingUrl: string;
  @Input() vis: boolean;
  @Input() scrollIntoView: 'top' | 'bottom' | 'smart';
  @Input() h: 1 | 2 | 3 | 4 | 5 | 6;
  @Output() vis$: EventEmitter<boolean> = new EventEmitter<boolean>(null);
  @Output() validerFoerFoldSammen$: EventEmitter<null> = new EventEmitter(null);

  accordionAmination: string = foldsammen;
  accordionVis: boolean = false;
  h2: boolean;
  h3: boolean;
  h4: boolean;
  h5: boolean;
  h6: boolean;
  buttonStyle: { [key: string]: number | string };
  buttonClass: string;
  statusClass: string;

  private gammelVis: boolean;
  @ViewChild('topElement', { static: true }) topElement: ElementRef;
  @ViewChild('bottomElement', { static: true }) bottomElement: ElementRef;
  @ViewChild('accordionButton', { static: false }) accordionButton: ElementRef;
  private accordionKlar: boolean = true;
  accordionAriaLabelMedStatusOgStatusTekst: string;

  constructor(private userTrackingService: UfmUserTrackingService) { }

  ngOnInit() {
    if (this.vis === true) {
      this.accordionVis = true;
      this.accordionAmination = foldud;
    }
    this.gammelVis = this.vis || false;
    // h2-h6 headings
    if (this.h === 2) {
      this.h2 = true;
      this.buttonStyle = { 'font-size': '26px' };
      this.buttonClass = 'btn-primary';
    }
    if (this.h === 3) {
      this.h3 = true;
      this.buttonStyle = { 'font-size': '24px' };
      this.buttonClass = 'btn-secondary';
      this.statusClass = 'text-dark';
    }
    if (this.h === 4) {
      this.h4 = true;
      this.buttonStyle = { 'font-size': '22px' };
      this.buttonClass = 'btn-light border';
      this.statusClass = 'text-dark';
    }
    if (this.h === 5) {
      this.h5 = true;
      this.buttonStyle = { 'font-size': '20px' };
      this.buttonClass = 'btn-light border';
      this.statusClass = 'text-dark';
    }
    if (this.h === 6) {
      this.h6 = true;
      this.buttonStyle = { 'font-size': '1rem' };
      this.buttonClass = 'btn-light border';
      this.statusClass = 'text-dark';
    }
    if (!this.h2 && this.h3 && this.h4 && this.h5 && this.h6) {
      this.h = null;
      this.buttonStyle = { 'font-size': '28px' };
      this.buttonClass = 'btn-primary';
    }
  }

  ngOnChanges() {
    const tekster: string[] = [];
    tekster.push(this.ariaLabel + (this.accordionVis ? ' expanded' : ' collapsed'));
    if (this.status === AccordionStatus.SkalUdfyldes) {
      tekster.push('Formular skal udfyldes');
    }
    if (this.status === AccordionStatus.Fejl) {
      tekster.push('Formular indeholder fejl');
    }
    if (this.statusTekst && !this.accordionVis) {
      tekster.push(this.statusTekst);
    }
    this.accordionAriaLabelMedStatusOgStatusTekst = tekster.join('. ');

    if (this.vis === undefined) {
      // console.log(this.overskrift, '/onChanges: ignorer onChanges event. vis er undefined');
      return;
    }
    // console.log(this.overskrift, '/onChanges: accordion/onChange: vent på at accordion er klar');
    let s: Subscription = interval(50).subscribe(() => {
      if (!this.accordionKlar) { return; }
      s.unsubscribe();
      // console.log(this.overskrift, '/onChanges: accordion/onChange: accordion er klar');
      // console.log(this.overskrift, '/onChanges: gammelVis=', this.gammelVis, ', vis=', this.vis);
      if (this.vis === this.gammelVis) {
        // console.log(this.overskrift, '/onChanges: fold ud/sammen tilstand er allerede ok');
        return;
      }
      // console.log(this.overskrift, '/onChanges: kalder accordion. vis=', this.vis, ', accordionAmination=', this.accordionAmination);
      this.gammelVis = this.vis;
      this.accordion(false);
    });
  }

  accordion(click: boolean): void {
    this.accordionKlar = false;
    if (this.accordionAmination === foldsammen) {
      this.accordionVis = true;
      timer(0).subscribe(() => {
        this.accordionAmination = foldud;
        this.accordionKlar = true;
        if (click) {
          this.gammelVis = this.accordionVis;
          this.vis$.emit(this.accordionVis);
        }
        if (this.scrollIntoView) {
          timer(400).subscribe(() => {
            if (this.scrollIntoView === 'top') {
              this.topElement.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              });
            } else if (this.scrollIntoView === 'bottom') {
              this.bottomElement.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
              });
            } else if (this.scrollIntoView === 'smart') {
              // tjek bund
              this.bottomElement.nativeElement.click();
            }
          });
        }
      });
      if (this.userTrackingUrl) {
        this.userTrackingService.sendUrl(this.userTrackingUrl + '/foldud');
      }
    } else {
      this.accordionAmination = foldsammen;
      this.validerFoerFoldSammen$.emit();
      timer(450).subscribe(() => {
        this.accordionVis = false;
        this.accordionKlar = true;
        if (click) {
          this.gammelVis = this.accordionVis;
          this.vis$.emit(this.accordionVis);
        }
      });
      if (this.userTrackingUrl) {
        this.userTrackingService.sendUrl(this.userTrackingUrl + '/foldsammen');
      }
    }
  }

  erElementSynligt(element: HTMLElement): boolean {
    var bounding = element.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  erBundSynlig(erSynligt: boolean): void {
    if (!erSynligt) {
      // vis bund. tjek top
      this.bottomElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
      this.topElement.nativeElement.click();
    }
  }

  erTopSynlig(erSynligt: boolean): void {
    if (!erSynligt) {
      // vis top
      this.topElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

}
