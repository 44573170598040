import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription} from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AnsoegningService } from './ansoegning.service';
import { HentAnsoegningService } from '../../core/hentAnsoegning.service';
import { ProfilService } from '../../core/profil.service';

@Component({
  selector: 'app-ansoegning',
  templateUrl: './ansoegning.component.html',
  styleUrls: ['./ansoegning.component.scss']
})
export class AnsoegningComponent implements OnInit {

  naesteTrinInput$: EventEmitter<string> = new EventEmitter<string>();
  form: UntypedFormGroup;
  @Input() foedtBarn: any;
  formData: FormData;
  terminClass: string = 'col-12 border';
  senderAnsoegning: boolean = false;
  ansoegningErBlevetSendt: boolean = false;
  faSpinner = faSpinner;
  subscriptions: Subscription[] = [];
  sekvensnrNuv?: string;
  sekvensnrKom?: string;
  sekvensnr?: string;
  instnavnNuv?: string;
  instnavnKom?: string;
  retningnavnNuv?: string;
  retningnavnKom?: string;
  uddBopal?: string;
  barn?: string[];
  fodseldt?: string[];
  ingenKomUdd: boolean;
  ansogeningData: any;
  uddstart?: string;

  constructor(
    private ansoegningService: AnsoegningService,
    private router: Router,
    private hentAnsoegningService: HentAnsoegningService,
    private profilService: ProfilService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.hentAnsoegningService
        .hentHentAnsoegning()
        .pipe(mergeMap(() => this.hentAnsoegningService.hentAnsoegning$))
        .subscribe((hentAnsoegning) => {
          this.sekvensnr = hentAnsoegning ? hentAnsoegning.sekvensnr : null;
          this.sekvensnrNuv = hentAnsoegning ? hentAnsoegning.sekvensnrNuv : null;
          this.sekvensnrKom = hentAnsoegning ? hentAnsoegning.sekvensnrKom : null;
          this.instnavnNuv = hentAnsoegning ? hentAnsoegning.instnavnNuv : null;
          this.instnavnKom = hentAnsoegning ? hentAnsoegning.instnavnKom : null;
          this.retningnavnNuv = hentAnsoegning ? hentAnsoegning.retningnavnNuv : null;
          this.retningnavnKom = hentAnsoegning ? hentAnsoegning.retningnavnKom : null;
          this.uddBopal = hentAnsoegning ? hentAnsoegning.uddBopal : null;
          this.uddstart = hentAnsoegning ? hentAnsoegning.uddstart : null;
          this.barn = hentAnsoegning ? hentAnsoegning.barn : null;
          this.fodseldt = hentAnsoegning ? hentAnsoegning.fodseldt : null;
     
          this.ansogeningData = {
            sekvensnrNuv: this.sekvensnrNuv,
            sekvensnrKom: this.sekvensnrKom,
            instnavnNuv:  this.instnavnNuv,
            instnavnKom: this.instnavnKom,
            retningnavnNuv: this.retningnavnNuv,
            retningnavnKom: this.retningnavnKom,
            uddBopal: this.uddBopal,
            uddstart: this.uddstart,
            barn: this.barn,
            fodseldt: this.fodseldt
          };  
  
          if (this.sekvensnrKom !== '00') {
            this.ingenKomUdd = true;
          }
          if (this.sekvensnrKom === '00' ) {
            this.ingenKomUdd = false;
            this.form.patchValue({
              sekvensnrKom: this.sekvensnrKom    
            });
            this.form.patchValue({
              sekvensnrNuv: this.sekvensnrNuv    
            });
            this.form.patchValue({
              sekvensnr: this.sekvensnrNuv    
            });
          }
        })
    );
    
    this.form = this.ansoegningService.initialiserAnsoegningFormGroup();  
    window.scrollTo(0, 0);
  }

  public saetTerminClass(border: string) {
    this.terminClass = 'col-12 border ' + border;
  }

  naesteTrin(sidsteComponent: string) {
    let componenter: string[] = ['TerminComponent'];
    let index: number = componenter.indexOf(sidsteComponent);
    if (index === -1) {
      console.error('Kunne ikke finde component', sidsteComponent);
      return;
    } else if (index >= componenter.length - 1) {
      return;
    }
    const naesteComponent: string = componenter[index + 1];
    this.naesteTrinInput$.emit(naesteComponent);
  }

  sendAnsoegning(): void {
    this.senderAnsoegning = true;
    this.ansoegningService
      .sendAnsoegning(this.form, this.ansogeningData)
      .subscribe((ok) => {
        this.profilService.hentProfil().subscribe();
        this.router.navigate(['/kvittering']);
      });  
  }
}
