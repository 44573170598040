import { UdbetalingsService2 } from '../../core/udbetalings2.service';
import { UdbetalingService } from './udbetaling.service';
import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ProfilService } from '../../core/profil.service';

@Component({
  selector: 'app-udbetaling',
  templateUrl: './udbetaling.component.html',
  styleUrls: ['./udbetaling.component.scss']
})
export class UdbetalingComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  formUdbetaling: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  EnkeltStoetteVis = false;
  DobbeltStoetteVis = false;
  UdbetalingVis = false;
  AlmStoetteVis = false;
  forrigeStoetteAar?;
  naesteStoetteAar?;
  tildeltKlip?;
  restklip?;
  restklipFoedsel?;
  restklipFoedselLogisk?;
  fravalgPer?;
  inaktiv?;
  periodeAaben?;
  udbetaltFoedsel?;
  ratestatusPre?;
  @Input() ratestatusPost?;
  ratestatusOk?;
  mmTekst?;
  surateKr?;
  foedselsrateKr?;
  stoetteAar?;

  januarKlip: number;
  februarKlip: number;
  martsKlip: number;
  aprilKlip: number;
  majKlip: number;
  juniKlip: number;
  juliKlip: number;
  augustKlip: number;
  septemberKlip: number;
  oktoberKlip: number;
  novemberKlip: number;
  decemberKlip: number;
  stoetteAarPlus1: number;
  tempVar?;
  tempString?;
  tempArray: String[];
  uddType?;
  senderUdbetaling: boolean = false;
  maaneder: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  brugteKlip: number;

  constructor(
    private router: Router,
    private udbetalingsService2: UdbetalingsService2,
    private profilService: ProfilService,
    private udbetalingService: UdbetalingService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.udbetalingsService2
        .hentUdbetaling()
        .pipe(mergeMap(() => this.udbetalingsService2.udbetaling$))
        .subscribe((udbetaling) => {
          this.naesteStoetteAar = udbetaling ? udbetaling.naesteStoetteAar : null;
          this.forrigeStoetteAar = udbetaling ? udbetaling.forrigeStoetteAar : null;
          this.restklip = udbetaling ? udbetaling.restklip : null;
          this.restklipFoedsel = udbetaling ? udbetaling.restklipFoedsel : null;
          this.fravalgPer = udbetaling ? udbetaling.fravalgPer : null;
          this.inaktiv = udbetaling ? udbetaling.inaktiv : null;
          this.periodeAaben = udbetaling ? udbetaling.periodeAaben : null;
          this.ratestatusPre = udbetaling ? udbetaling.ratestatusPre : null;
          this.ratestatusPost = udbetaling ? udbetaling.ratestatusPost : null;
          this.ratestatusOk = udbetaling ? udbetaling.ratestatusOk : null;
          this.mmTekst = udbetaling ? udbetaling.mmTekst : null;
          this.surateKr = udbetaling ? udbetaling.surateKr : null;
          this.foedselsrateKr = udbetaling ? udbetaling.foedselsrateKr : null;
          this.tildeltKlip = udbetaling ? udbetaling.tildeltKlip : null;
          this.udbetaltFoedsel = udbetaling ? udbetaling.udbetaltFoedsel : null;
          this.restklipFoedselLogisk = udbetaling ? udbetaling.restklipFoedselLogisk : null;
          this.stoetteAar = udbetaling ? udbetaling.stoetteAar : null;
          this.stoetteAar = this.stoetteAar.slice(0, 1) + '0' + this.stoetteAar.slice(1);
          this.stoetteAarPlus1 = parseInt(this.stoetteAar);
          this.stoetteAarPlus1 = this.stoetteAarPlus1 + 1;

          this.formUdbetaling = this.udbetalingService.initialiserUdbetalingFormGroup();
          
          const formUdbetalingMaaneder = this.formUdbetaling.controls.maaneder as UntypedFormArray;

          for (let maanedsNr = 0; maanedsNr < 12; maanedsNr++) {
            const formudbetalingMaaned = formUdbetalingMaaneder.at(maanedsNr) as UntypedFormGroup;
            const udbetalingsTypeFormControl = formudbetalingMaaned.controls.udbetalingsType;
            udbetalingsTypeFormControl.valueChanges.subscribe((udbetalingsType) => {
              const foedselsKlip = this.udbetalingService.rateTilKlip(udbetalingsType);
              formudbetalingMaaned.controls.foedselsKlip.setValue(foedselsKlip);
            });
          }
          //formUdbetalingMaaneder.value
          const maaneder = formUdbetalingMaaneder.value;
          this.brugteKlip = maaneder
            .map((maaned) => {
              if (maaned.periodeAaben === '+') {
                return maaned.foedselsKlip;
              } else {
                return 0;
              }
            })
            .reduce((partialSum, a) => partialSum + a, 0);

          formUdbetalingMaaneder.valueChanges.subscribe((maaneder) => {
            this.brugteKlip = maaneder
              .map((maaned) => {
                if (maaned.periodeAaben === '+') {
                  return maaned.foedselsKlip;
                } else {
                  return 0;
                }
              })
              .reduce((partialSum, a) => partialSum + a, 0);
          });
        })
    );
    this.subscriptions.push(
      this.profilService.profil$.subscribe((profil) => {
        this.uddType = profil ? profil.uddType : null;
      })
    );
  }

  getMaaned(maaned: number): UntypedFormGroup {
    const maaneder: UntypedFormArray = this.formUdbetaling.controls.maaneder as UntypedFormArray;
    return maaneder.at(maaned) as UntypedFormGroup;
  }

  aabnedeLukkedeEnkeltStoette(vis: boolean): void {
    this.EnkeltStoetteVis = vis;
  }
  aabnedeLukkedeDobbeltStoette(vis: boolean): void {
    this.DobbeltStoetteVis = vis;
  }
  aabnedeLukkedeAlmStoette(vis: boolean): void {
    this.AlmStoetteVis = vis;
  }
  aabnedeLukkedeUdbetaling(vis: boolean): void {
    this.UdbetalingVis = vis;
  }

  sendUdbetaling(): void {
    this.senderUdbetaling = true;
    this.udbetalingService
      .sendUdbetaling(this.formUdbetaling)
      .subscribe((ok) => {
        this.router.navigate(['/udbetaling-kvittering']);
      });
  }
}