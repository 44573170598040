import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faCheckCircle, faTimesCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';

import { AccordionStatus } from './accordion-status.enum';

@Component({
  selector: 'app-accordion-status',
  templateUrl: './accordion-status.component.html',
  styleUrls: ['./accordion-status.component.scss']
})
export class AccordionStatusComponent implements OnInit, OnChanges {

  @Input() form: UntypedFormGroup;
  @Input() status: AccordionStatus;
  @Input() ngClass: string;

  accordionStatusEnum = AccordionStatus;

  ok: boolean;
  fejl: boolean;
  skalUdfyldes: boolean;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faExclamation = faExclamation;

  constructor() { }

  ngOnInit() {
    if (!this.ngClass) {
      this.ngClass = 'text-white' ;
    }
    this.ngOnChanges();
  }

  ngOnChanges() {
    this.ok = this.status === AccordionStatus.Ok;
    this.skalUdfyldes = this.status === AccordionStatus.SkalUdfyldes;
    this.fejl = this.status === AccordionStatus.Fejl;
  }
}
