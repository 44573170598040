import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'tid'
})
export class TidPipe implements PipeTransform {

  constructor(private datePipe: DatePipe, @Inject(LOCALE_ID) private locale: string) { }

  // thomas rtc 22705: KLOKKEN, DD-MM-ÅÅÅÅ
  transform(value: Date): any {
    if (value) {
      // console.log('value=', value, ', typeof=', typeof value, ', instance of date', value instanceof Date);
      return this.datePipe.transform(value, 'HH:mm, dd-MM-yyyy');
    }
  }
}
