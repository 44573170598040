import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { timer, Subscription, Observable, of } from 'rxjs';
import { concatMap, mergeMap } from 'rxjs/operators';
import { ProfilService } from '../../../core/profil.service';
import { AccordionComponent } from '../../../shared/ui/accordion/accordion.component';
import { AccordionStatus } from '../../../shared/ui/accordion/accordion-status/accordion-status.enum';
import { foldudAmination } from '../../../shared/animationer/animationer';

const foldud = 'foldud';
const foldsammen = 'foldsammen';

@Component({
  selector: 'app-studieforloeb',
  templateUrl: './studieforloeb.component.html',
  styleUrls: ['./studieforloeb.component.scss'],
  animations: [foldudAmination]
})
export class StudieforloebComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() naesteTrinInput$: Observable<string>;
  @Output() naesteTrinOutput$ = new EventEmitter<string>();

  status: AccordionStatus;
  statusTekst: string;
  studieforloebVis = false;

  @ViewChild('error1', { static: false }) error1: ElementRef;
  @ViewChild('error2', { static: false }) error2: ElementRef;
  @ViewChild('studieforloebAccordion', { static: true }) studieforloebAccordion: ElementRef;
  @ViewChild('topElement', { static: true }) topElement: ElementRef;
  @ViewChild('bottomElement', { static: false }) bottomElement: ElementRef;

  studieaktivVis: boolean;
  studieaktiv2Vis: boolean;
  studieforloeb2Vis: boolean;
  studieaktivAnimation = foldsammen;
  studieforloebAnimation = foldsammen;
  stoppetArbejdeAarsagVis: boolean;
  subscriptions: Subscription[] = [];
  uddType;
  uddTypeTrueFalse: boolean;
  constructor( private profilService: ProfilService) { }

  ngOnInit() {
    // fold studieforløb sammen  + fold terminsdato ud

    this.subscriptions.push(
      this.profilService
        .hentProfil()
        .pipe(mergeMap(() => this.profilService.profil$))
        .subscribe((profil) => {
          this.uddType = profil ? profil.uddType : null; 
          if (this.uddType == 'VU') {
            this.uddTypeTrueFalse = true;
          }
          if(this.uddType == 'UU') {
            this.uddTypeTrueFalse = false;
          }        
        })
    );
    this.subscriptions.push(
      this.naesteTrinInput$.subscribe((naesteComponent) => {
        if (naesteComponent === 'StudieforloebComponent' && !this.studieforloebVis) {
          // fold ud
          this.studieforloebVis = true;
          timer(400).subscribe(() => {
            const studieforloebAccordion = this.studieforloebAccordion as any as AccordionComponent;
            studieforloebAccordion.accordionButton.nativeElement.focus();
          });
        }
      })
    );
    // Check på udd status der SKAL udfyldes af bruger, ingen data sendes til backend fra dette check.
    this.status = AccordionStatus.SkalUdfyldes;
    this.subscriptions.push(
      this.form.valueChanges.subscribe((values) => {
        if(this.uddType === 'VU'){
        if (values.uddStatus === 'Ja') {
          this.status = AccordionStatus.Ok;
        }
        if (values.uddStatus === 'Nej') {
          if (values.studieaktiv === 'Ja') {
            this.status = AccordionStatus.Ok;           
          } else if (values.studieaktiv === 'Nej') {
            this.status = AccordionStatus.Ok;
          } else if (values.studieaktiv === null) {
            this.status = AccordionStatus.Ok;
          }
        }
      }
      if(this.uddType === 'UU'){
        if (values.uddStatus === 'Ja') {
          this.status = AccordionStatus.Ok;
        }
        if (values.uddStatus === 'Nej') {
            this.status = AccordionStatus.Ok;
        }
      }
      })
    );
    // uddStatus animationer
    this.subscriptions.push(
      this.form.controls.uddStatus.valueChanges.subscribe((harArbejdet) => {
        if (harArbejdet === 'Nej') {
          this.studieforloebJaFoldsammen()
            .pipe(concatMap(() => this.studieforloebNejFoldud()))
            .subscribe(() => {
              this.stoppetArbejdeAarsagVis = this.studieaktivVis;
            });
        } else {
          this.studieforloebNejFoldsammen()
            .pipe(concatMap(() => this.studieforloebJaFoldud()))
            .subscribe(() => {
              this.stoppetArbejdeAarsagVis = this.studieaktivVis;
          });
        }
      })
    );
    // studieaktiv: skal der tilføjes animation til "Beskriv forholdene" feltet?
    this.subscriptions.push(
      this.form.controls.studieaktiv.valueChanges.subscribe((studieaktiv) => {
        this.studieaktiv2Vis = studieaktiv === 'Nej';
      })
    );
  }
    // de fire næste metoder er forskellige variationer af drop down menuen for studieforløb
  private studieforloebNejFoldud(): Observable<null> {
    if (this.studieaktivVis) {
      return of(null);
    } else {
      return new Observable((observer) => {
        this.studieaktivVis = true;
        timer(0).subscribe(() => {
          this.studieaktivAnimation = foldud;
          timer(450).subscribe(() => {
            observer.next();
            observer.complete();
          });
        });
      });
    }
  }

  private studieforloebNejFoldsammen(): Observable<null> {
    if (!this.studieaktivVis) {
      return of(null);
    } else {
      return new Observable((observer) => {
        this.studieaktivAnimation = foldsammen;
        timer(500).subscribe(() => {
          this.studieaktivVis = false;
          observer.next();
          observer.complete();
        });
      });
    }
  }

  private studieforloebJaFoldud(): Observable<null> {
    if (this.studieforloeb2Vis) {
      return of(null);
    } else {
      return new Observable((observer) => {
        this.studieforloeb2Vis = true;
        timer(0).subscribe(() => {
          this.studieforloebAnimation = foldud;
          timer(450).subscribe(() => {
            observer.next();
            observer.complete();
          });
        });
      });
    }
  }

  private studieforloebJaFoldsammen(): Observable<null> {
    if (!this.studieforloeb2Vis) {
      return of(null);
    } else {
      return new Observable((observer) => {
        this.studieforloebAnimation = foldsammen;
        timer(500).subscribe(() => {
          this.studieforloeb2Vis = false;
          observer.next();
          observer.complete();
        });
      });
    }
  }

  ngOnDestroy() {
    let subscription: Subscription;
    while (this.subscriptions.length) {
      subscription = this.subscriptions.shift();
      subscription.unsubscribe();
    }
  }

  aabnedeLukkedeStudieforloeb(vis: boolean): void {
    this.studieforloebVis = vis;
  }

  private opdaterStatusTekst(): void {
    const fejl: string[] = [];
    if (this.error1) { fejl.push(this.error1.nativeElement.innerText); }
    if (this.error2) { fejl.push(this.error2.nativeElement.innerText); }
    if (fejl.length) {
      this.statusTekst = fejl.join('. ');
    } else {
      this.statusTekst = null;
    }
  }

  validerFoerFoldSammen(): void {
    for (let key in this.form.controls) {
      this.form.controls[key].markAsTouched();    
    }
    timer(0).subscribe(() => {
      this.opdaterStatusTekst();
    });
  }

  tjekNaesteTrin(event: KeyboardEvent): void {
    if (this.form.valid) {
      this.studieforloebVis = false;
      this.naesteTrinOutput$.emit('StudieforloebComponent');
    } else {
      event.preventDefault();
    }
  }
}