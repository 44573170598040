<div class="lilla-baggrund p-0">
  <nav class="container navbar navbar-expand-md navbar-dark">

    <a class="navbar-brand rounded pt-0 pb-0" routerLink="/" routerLinkActive="active">
      <img src="assets/img/logo_ufm_dk_hvid_72_dpi_web_480.png" height="50" class="d-inline-block align-top"
        alt="Link til foedselsstoette ansøgning forside" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="isCollapsed = !isCollapsed"
      aria-expanded="false" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
      [attr.aria-expanded]="!isCollapsed" aria-label="Menu knap">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNavDropdown" [collapse]="isCollapsed">
      <ul class="navbar-nav me-auto"></ul>
      <ul class="navbar-nav">
      <li *ngIf="brugernavn">
          <span class="nav-text pe-5 text-white"><b>{{ brugernavn }}</b></span>
        </li>
        <li class="nav-item pe-3">
          <a class="text-white text-end" href="#" (click)="minSU()" aria-label="Gå til min S U selvbetjening">minSU</a>
        </li>
        <li class="nav-item" [ngClass]="visLogPaa">
          <a class="text-white text-end" href="#" (click)="logAf()" aria-label="Log ud af selvbetjening">Log af</a>
        </li>
        <li class="nav-item" [ngClass]="visLogAf">
          <a class="text-white text-end" href="#" (click)="logPaa()" aria-label="Log på selvbetjening">Log på</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
