import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment/moment';
import { Udbetaling } from '../../../core/udbetaling';

import { UdbetalingsService2 } from '../../../core/udbetalings2.service';

import { UdbetalingService } from '../udbetaling.service';

@Component({
  selector: 'app-udbetaling-maaned',
  templateUrl: './udbetaling-maaned.component.html',
  styleUrls: ['./udbetaling-maaned.component.scss']
})
export class UdbetalingMaanedComponent implements OnInit {
  @Input() formUdbetalingMaaned: UntypedFormGroup;
  maanedsNr: number;
  maaned: String;
  udbMaaned: String;
  periodeAaben: String;
  udbetaltFoedsel: String[] = this.udbetalingsService2.udbetaling$.value.udbetaltFoedsel;
  maaneder: {
    ratestatus: string;
    ratestatusTekst: string;
  }[];

  constructor(private udbetalingsService2: UdbetalingsService2,private udbetalingService: UdbetalingService) { }

  ngOnInit(): void {
    const maanedsNr = this.formUdbetalingMaaned.controls.maaned.value as number;
    this.maanedsNr = maanedsNr;
    this.maaned = moment().locale('da').month(maanedsNr).format('MMMM');
    const udbetaling: Udbetaling = this.udbetalingsService2.udbetaling$.value;
    this.periodeAaben = this.formUdbetalingMaaned.controls.periodeAaben.value;
    this.maaneder = udbetaling.ratestatusOk[maanedsNr]
      .split('')
      .map((ratestatus) => {
        const maaned = { ratestatus: ratestatus, ratestatusTekst: this.udbetalingService.getRatestatustekst(ratestatus)};
        return maaned;
      });
  }

}
