import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { TypeaheadModule, TypeaheadConfig } from 'ngx-bootstrap/typeahead';
import { CookieService } from 'ngx-cookie-service';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';

// ufm moduler
import { UfmBackendtid1Module } from '@ufmit/ufm-backendtid1';
import { UfmFejlhaandteringModule } from '@ufmit/ufm-fejlhaandtering';
import { UfmFuldmagtModule } from '@ufmit/ufm-fuldmagt';
import { UfmPropertiesModule } from '@ufmit/ufm-properties';
import { UfmSessionModule } from '@ufmit/ufm-session';
import { UfmUserTrackingModule } from '@ufmit/ufm-user-tracking';

// services
import { AnsoegningService } from './forretnings-sider/ansoegning/ansoegning.service';
import { ProfilService } from './core/profil.service';

// pipes
import { LangdatoPipe } from './shared/dato/langdato-pipe/langdato.pipe';

// components
import { AccordionComponent } from './shared/ui/accordion/accordion.component';
import { AccordionPrefixComponent } from './shared/ui/accordion/accordion-prefix/accordion-prefix.component';
import { AccordionStatusComponent } from './shared/ui/accordion/accordion-status/accordion-status.component';
import { AccordionTekstComponent } from './shared/ui/accordion/accordion-tekst/accordion-tekst.component';
import { AnsoegningComponent } from './forretnings-sider/ansoegning/ansoegning.component';
import { AppComponent } from './app.component';
import { KvitteringComponent } from './forretnings-sider/kvittering/kvittering.component';
import { MenuComponent } from './side-struktur/menu/menu.component';
import { SamtykkeComponent } from './forretnings-sider/ansoegning/samtykke/samtykke.component';
import { SideBundComponent } from './side-struktur/side-bund/side-bund.component';
import { SekvensnrComponent } from './forretnings-sider/ansoegning/sekvensnr/sekvensnr.component';
import { TerminComponent } from './forretnings-sider/ansoegning/termin/termin.component';
import { TidPipe } from './shared/dato/tid-pipe/tid.pipe';
import { StudieforloebComponent } from './forretnings-sider/ansoegning/studieforloeb/studieforloeb.component';
import { enligforaeldreComponent } from './forretnings-sider/ansoegning/enligforaeldre/enligforaeldre.component';
import { OverblikComponent } from './forretnings-sider/overblik/overblik.component';
import { UdbetalingComponent } from './forretnings-sider/udbetaling/udbetaling.component';

import { registerLocaleData } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { daLocale } from 'ngx-bootstrap/locale';
import da from '@angular/common/locales/da';
registerLocaleData(da);
defineLocale('da', daLocale);

import { environment } from '../environments/environment';
import { AfslagComponent } from './forretnings-sider/kvittering/Afslag/afslag/afslag.component';
import { GodkendelseComponent } from './forretnings-sider/kvittering/godkendelse/godkendelse/godkendelse.component';
import { SharedComponent } from './forretnings-sider/kvittering/shared/shared/shared.component';
import { UdbetalingService } from './forretnings-sider/udbetaling/udbetaling.service';
import { UdbetalingsService2 } from './core/udbetalings2.service';
import { UdbetalingMaanedComponent } from './forretnings-sider/udbetaling/udbetaling-maaned/udbetaling-maaned.component';
import { UdbetalingKvitteringComponent } from './forretnings-sider/udbetaling/udbetaling-kvittering/udbetaling-kvittering.component';

export function getBsDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'DD-MM-YYYY',
    containerClass: 'theme-default'
  });
}

function loadEnvironmentJson(http: HttpClient): Promise<boolean | void> {
  const headers: { [key: string]: string } = { NoInterceptorAgerendeCpr: 'x' };
  return new Promise<boolean>((resolve) => {
      http
        .get('assets/environment.json', { headers: headers })
        .toPromise()
        .then(environmentValues => {
          for (const key in environmentValues) {
            if (environmentValues.hasOwnProperty(key)) {
              environment[key] = environmentValues[key];
            }
          }
          resolve(true);
        })
        .catch(function (reason) {
          console.error(
            '1: Failed to load environment properties. reason=',
            reason
            );
        });
    });
}

function initializeKeycloak(
  keycloakService: KeycloakService
  ): Promise<boolean | void> {
  const script = document.createElement('script');
  script.src = environment.keycloak.url + '/js/keycloak.js';
  document.body.appendChild(script);
  if (environment.demo) {
    const re: RegExp = new RegExp('\\?requestid=(\\d+)');
    const m: RegExpMatchArray = window.location.href.match(re);
    if (m) {
      const us2000RequestId: string = m[1];
      sessionStorage.setItem('us2000RequestId', us2000RequestId);
    }
  }
  return keycloakService
    .init({
    config: environment.keycloak,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: 
        window.location.origin + '/assets/silent-check-sso.html'
    }
  })
  .catch(function (reason) {
    console.error('2: Failed to initialize Keycloak', reason);
  });
}

function initializeApplication(
  httpClient: HttpClient,
  keycloakService: KeycloakService
  ) {
  return () => 
   loadEnvironmentJson(httpClient)
    .then(() => initializeKeycloak(keycloakService))
    .catch(function (reason) {
      console.error('3: Failed to initialize applikation', reason);
    });
}

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionPrefixComponent,
    AccordionStatusComponent,
    AccordionTekstComponent,
    AnsoegningComponent,
    AppComponent,
    KvitteringComponent,
    LangdatoPipe,
    MenuComponent,
    SamtykkeComponent,
    SideBundComponent,
    UdbetalingComponent,
    SekvensnrComponent,
    TerminComponent,
    StudieforloebComponent,
    enligforaeldreComponent,
    OverblikComponent,
    TidPipe,
    AfslagComponent,
    GodkendelseComponent,
    SharedComponent,
    UdbetalingMaanedComponent,
    UdbetalingKvitteringComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    LoadingBarHttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    TypeaheadModule,
    UfmBackendtid1Module.forRoot({
      environment: { apiPropertyNavn: 'foApi' },
      tekster: { hentet: 'Hentet:' }
    }),
    UfmFejlhaandteringModule,
    UfmFuldmagtModule.forRoot({ apiLoginEndpoint: 'loginsb' }),
    UfmPropertiesModule.forRoot({ environment: environment }),
    UfmSessionModule.forRoot({ logAfUrl: '/logaf', apiLoginEndpoint: 'loginsb' }),
    UfmUserTrackingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      multi: true,
      deps: [HttpClient, KeycloakService]
    },
    { provide: LOCALE_ID, useValue: 'da-DK' },
    { provide: BsDatepickerConfig, useFactory: getBsDatepickerConfig },
    AnsoegningService,
    UdbetalingService,
    UdbetalingsService2,
    CookieService,
    DatePipe,
    ProfilService,
    TypeaheadConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
