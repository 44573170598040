<div class="py-5 mt-5 border-top bg-light">
  <footer class="container">
    <div class="text-muted">
      <img src="assets/img/ufm_brand_35.svg"
        width="19" height="19" class="d-inline-block align-top" alt="Min.su.dk - selvbetjening og information" />
      Uddannelses- og Forskningsstyrelsen
    </div>
    <div>
      <a  href="https://www.was.digst.dk/foedselsstoette-uddannelsesstoette-dk" target="_blank"
        aria-label="Gå til foedselsstoette.uddannelsesstoette.dk. Åbner i nyt faneblad"><u>Tilgængelighedserklæring</u></a>
    </div>
  </footer>
</div>
