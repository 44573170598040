import { Component, OnInit, OnDestroy } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { mergeMap, map, distinctUntilChanged, filter, flatMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { HentAnsoegningService } from '../../core/hentAnsoegning.service';
import { ProfilService } from '../../core/profil.service';
import { UdbetalingsService2 } from '../../core/udbetalings2.service';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';
import { UfmSessionLoginService, UfmSessionLogPaaStatus } from '@ufmit/ufm-session';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  isCollapsed = false;
  brugernavn: string = null;
  subscriptions: Subscription[] = [];
  visLogPaa: string;
  visLogAf: string;

  constructor(
    private hentAnsoegningService: HentAnsoegningService,
    private profilService: ProfilService,
    private udbetalingsService2: UdbetalingsService2,
    private ufmFuldmagtService: UfmFuldmagtService,
    private ufmSessionLoginService: UfmSessionLoginService,
    private ufmUserTrackingService: UfmUserTrackingService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.ufmFuldmagtService.fuldmagtsBruger$
        .pipe(
          map((ufmFuldmagtBruger) => {
            if (ufmFuldmagtBruger && ufmFuldmagtBruger.agerendeCpr) {
              return ufmFuldmagtBruger.agerendeCpr;
            } else {
              return null;
            }
          }),
          distinctUntilChanged(),
          filter((agerendeCpr) => (agerendeCpr ? true : false)),
          mergeMap(() => this.profilService.hentProfil()),
          map(() => this.profilService.profil$.value)
        )
        .subscribe((profil) => {
        this.brugernavn = profil ? profil.navn : null;
        
        // første kald & skift af agerende fuldmagtsbruger. Refresh oplysninger der anvendes i forsiden /
        this.hentAnsoegningService.hentHentAnsoegning().subscribe();
        this.udbetalingsService2.hentUdbetaling().subscribe();
      })
    );
    timer(50).subscribe(() => (this.isCollapsed = true));
    this.subscriptions.push(
      this.ufmSessionLoginService.loggetPaa$.subscribe((erLoggetPaa) => {
        if (erLoggetPaa === UfmSessionLogPaaStatus.LoggetPaa) {
          this.visLogPaa = 'd-block';
          this.visLogAf = 'd-none';
        } else {
          this.visLogPaa = 'd-none';
          this.visLogAf = 'd-block';
        }
      })
    );
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      s.unsubscribe();
    }
  }

  logPaa(): boolean {
    this.ufmSessionLoginService.logPaa();
    return false;
  }

  logAf(): boolean {
    this.ufmSessionLoginService.logAf();
    this.brugernavn = null;
    return false;
  }

  minSU(): void {
    this.ufmUserTrackingService.sendUrl('/minSU');
    window.location.href = environment.minsuUrl;
  }
}
