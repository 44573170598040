import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, ValidationErrors, UntypedFormBuilder, ValidatorFn, UntypedFormArray } from '@angular/forms';

import { ApiUdbetaling } from './api-udbetaling';
import { Udbetaling } from '../../core/udbetaling';
import { UdbetalingsService2 } from '../../core/udbetalings2.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UdbetalingService {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private udbetalingsService2: UdbetalingsService2
  ) {}

  subscriptions: Subscription[] = [];
  public apiUbetaling: ApiUdbetaling;
  kvitteringsData: any;
  
  initialiserUdbetalingFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ratestatusOk: [null],
      maaneder: this.formBuilder.array([
        this.initialiserUdbetalingMaanedFormGroup(0),
        this.initialiserUdbetalingMaanedFormGroup(1),
        this.initialiserUdbetalingMaanedFormGroup(2),
        this.initialiserUdbetalingMaanedFormGroup(3),
        this.initialiserUdbetalingMaanedFormGroup(4),
        this.initialiserUdbetalingMaanedFormGroup(5),
        this.initialiserUdbetalingMaanedFormGroup(6),
        this.initialiserUdbetalingMaanedFormGroup(7),
        this.initialiserUdbetalingMaanedFormGroup(8),
        this.initialiserUdbetalingMaanedFormGroup(9),
        this.initialiserUdbetalingMaanedFormGroup(10),
        this.initialiserUdbetalingMaanedFormGroup(11),
      ])
    }, { validators: [this.ValiderFoedselsKlip()] });
  }

  initialiserUdbetalingMaanedFormGroup(maanedsNr: number): UntypedFormGroup {
    const udbetaling: Udbetaling = this.udbetalingsService2.udbetaling$.value;
    const udbetalingsType = udbetaling.ratestatusPre[maanedsNr];
    let foedselsKlip = this.rateTilKlip(udbetalingsType);
    const periodeAaben = udbetaling.periodeAaben[maanedsNr];
    return this.formBuilder.group({
      maaned: [maanedsNr],
      maanedsNavn: [moment().locale('da').month(maanedsNr).format('MMMM')],
      udbetalingsType: [udbetalingsType],
      foedselsKlip: [foedselsKlip],
      periodeAaben: [periodeAaben]
    });
  }
  //Klasse til konvertering af ratetype til antal klip
  rateTilKlip(variabel) {
    if (!variabel) {
      variabel = null;
    } else if (variabel === 'F') {
      variabel = 0;
    } else if (variabel === 'M') {
      variabel = 1;
    } else if (variabel === 'N') {
      variabel = 0;
    } else if (variabel === 'P') {
      variabel = 2;
    } else if (variabel === 'T') {
      variabel = 0;
    } else if (variabel === 'V') {
      variabel = 1;
    } else if (variabel === 'W') {
      variabel = 2;
    } else if (variabel === 'B') {
      variabel = 0;
    } else if (variabel === 'E') {
      variabel = 0;
    } else if (variabel === 'G') {
      variabel = 0;
    } else if (variabel === 'I') {
      variabel = 0;
    } else if (variabel === 'K') {
      variabel = 0;
    } else if (variabel === 'L') {
      variabel = 0;
    } else if (variabel === 'O') {
      variabel = 0;
    } else if (variabel === 'S') {
      variabel = 0;
    } else if (variabel === 'U') {
      variabel = 0;
    } else if (variabel === 'Ø') {
      variabel = 0;
    } else if (variabel === 'C') {
      variabel = 0;
    }

    return variabel;
  }
  //Klasse til konvertering af rateværdi til læsbar ratetype
  public getRatestatustekst(variabel) {
    if (variabel === 'F') {
      variabel = 'Fravalgt støtte';
    } else if (variabel === 'M') {
      variabel = 'Fødsels-støtte med alm. SU';
    } else if (variabel === 'N') {
      variabel = 'Almindelig SU';
    } else if (variabel === 'P') {
      variabel = 'Dobbeltklip før praktik';
    } else if (variabel === 'T') {
      variabel = 'Slutlån';
    } else if (variabel === 'V') {
      variabel = 'Enkelt fødsels-støtte';
    } else if (variabel === 'W') {
      variabel = 'Dobbelt fødsels-støtte';
    } else if (variabel === 'B') {
      variabel = 'Ikke støtteberettiget';
    } else if (variabel === 'E') {
      variabel = 'SU ikke søgt';
    } else if (variabel === 'G') {
      variabel = 'Periode ikke SU-berettiget';
    } else if (variabel === 'I') {
      variabel = 'Studieinaktiv';
    } else if (variabel === 'K') {
      variabel = 'Ikke flere klip';
    } else if (variabel === 'L') {
      variabel = 'Lønnet praktik';
    } else if (variabel === 'O') {
      variabel = 'Orlov';
    } else if (variabel === 'S') {
      variabel = 'Anden offentlig støtte';
    } else if (variabel === 'U') {
      variabel = 'Uden for uddannelse';
    } else if (variabel === 'Ø') {
      variabel = 'Ikke støtteberettiget';
    } else if (variabel === 'C') {
      variabel = 'Under 18 år, ingen SU';
    }

    return variabel;
  }

  sendUdbetaling(formgroup: UntypedFormGroup) {
    const udbetaling: Udbetaling = this.udbetalingsService2.udbetaling$.value;
    this.apiUbetaling = new ApiUdbetaling({});
    const json: any = formgroup.value;
    
    this.kvitteringsData = formgroup.value;
    this.apiUbetaling.forrigeStoetteAar = udbetaling.forrigeStoetteAar;
    this.apiUbetaling.naesteStoetteAar = udbetaling.naesteStoetteAar;
    this.apiUbetaling.restklip = udbetaling.restklip;
    this.apiUbetaling.restklipFoedsel = udbetaling.restklipFoedsel;
    this.apiUbetaling.fravalgPer = udbetaling.fravalgPer;
    this.apiUbetaling.inaktiv = udbetaling.inaktiv;
    this.apiUbetaling.periodeAaben = udbetaling.periodeAaben;
    this.apiUbetaling.ratestatusPre = udbetaling.ratestatusPre;
    this.apiUbetaling.ratestatusPost = json.maaneder.map((maaned)=>maaned.udbetalingsType);
    this.apiUbetaling.ratestatusOk = udbetaling.ratestatusOk;
    this.apiUbetaling.mmTekst = udbetaling.mmTekst;
    this.apiUbetaling.surateKr = udbetaling.surateKr;
    this.apiUbetaling.foedselsrateKr = udbetaling.foedselsrateKr;
    return this.http.post<ApiUdbetaling>('/api/udbetaling', this.apiUbetaling);
  }

  //klasse der sørger for at brugte fødselsklip ikke overstiger den rent faktiske mængde 
  ValiderFoedselsKlip(): ValidatorFn {
    return (fg: UntypedFormGroup): ValidationErrors => {
      if (!fg) {
        return null;
      }
      const fa : UntypedFormArray = fg.controls.maaneder as UntypedFormArray;
      let sumAfKlip: number = 0;
      for (let i = 0; i < 12; i++) {
        const maanedsFormgroup: UntypedFormGroup = fa.at(i) as UntypedFormGroup;
        const foedselsKlip: number = maanedsFormgroup.controls.foedselsKlip.value;
        const periodeAaben: String = maanedsFormgroup.controls.periodeAaben.value;
        if(periodeAaben == '-'){
        } else if (periodeAaben == '+'){
          sumAfKlip += foedselsKlip;
        }
      }
      const restKlipFoedsel = this.udbetalingsService2.udbetaling$.value.restklipFoedselLogisk;

      if (sumAfKlip > restKlipFoedsel) {
        return { forMangeKlip: true };
      }
    };
  }
}
