import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { timer, Subscription, Observable, of } from 'rxjs';
import { AccordionComponent } from '../../../shared/ui/accordion/accordion.component';
import { AccordionStatus } from '../../../shared/ui/accordion/accordion-status/accordion-status.enum';
import { foldudAmination } from '../../../shared/animationer/animationer';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

const foldsammen = 'foldsammen';

@Component({
  selector: 'app-enligforaeldre',
  templateUrl: './enligforaeldre.component.html',
  styleUrls: ['./enligforaeldre.component.scss'],
  animations: [foldudAmination]
})
export class enligforaeldreComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() naesteTrinInput$: Observable<string>;
  @Output() naesteTrinOutput$ = new EventEmitter<string>();

  status: AccordionStatus;
  statusTekst: string;
  userTrackingUrl: string;
  enlig: string;

  @ViewChild('enligforaeldreAccordion', { static: true }) enligforaeldreAccordion: ElementRef;

  harArbejdetVis: boolean;
  saerligeVilkaarVis: boolean;
  harIkkeArbejdetVis: boolean;
  visStartMaaned: boolean = true;
  enligJa: boolean = false;
  visenligforaeldre: boolean;
  vis: boolean = true;
  @ViewChild('topElement', { static: true }) topElement: ElementRef;
  @ViewChild('bottomElement', { static: false }) bottomElement: ElementRef;
  harArbejdetAnimation = foldsammen;
  harIkkeArbejdetAnimation = foldsammen;
  studiejobAntalTimerVis: boolean;
  stoppetArbejdeAarsagVis: boolean;
  subscriptions: Subscription[] = [];
  studiejob: boolean = null;
  taeller: { [key: string]: string } = {};

  constructor(private userTrackingService: UfmUserTrackingService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.naesteTrinInput$.subscribe((naesteComponent) => {
        if (naesteComponent === 'enligforaeldreComponent') {
          // fold ud
          timer(400).subscribe(() => {
            const enligforaeldreAccordion = this.enligforaeldreAccordion as any as AccordionComponent;
            enligforaeldreAccordion.accordionButton.nativeElement.focus();
          });
        }
      })
    );
  }

  public vaelgCheckBox(values: any) {
    if (values.currentTarget.checked) {
      this.status = AccordionStatus.Ok;
      this.enligJa = true;
    } else if (!values.currentTarget.checked) {
      this.status = AccordionStatus.Ok;
      this.enligJa = false;
    }
  }

  ngOnDestroy() {
    let subscription: Subscription;
    while (this.subscriptions.length) {
      subscription = this.subscriptions.shift();
      subscription.unsubscribe();
    }
  }

  aabnedeLukkedeenligforaeldre(vis: boolean): void {
    timer(0).subscribe(() => {
      this.vis = vis;
    });
  }

  tjekNaesteTrin(event: KeyboardEvent): void {
    if (this.form.valid) {
      this.naesteTrinOutput$.emit('enligforaeldreComponent');
    } else {
      event.preventDefault();
    }
  }
}