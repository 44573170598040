<div class="row">

    <div *ngIf="studerendeVis" class="col-12 pb-3 text-center" >
        <h1 style="text-align: center; padding:0px">Fødsels-støtte</h1>
        <br /><span><strong style="color: red">Du har ikke en aktiv SU-ansøgning</strong></span>
    </div>

    <app-accordion
    *ngIf="studerendeVis"
    class="col-12 pb-3"
    [ariaLabel]="' Gemt sektion for alle andre end folk der ikke er registreret med en aktiv uddannelse'"
    [h]="2"
    [overskrift]="' Jeg går på en videregående uddannelse'"
    [vis]="vuVis"
    [scrollIntoView]="'smart'"
    (vis$)="vuHarmonika($event)"
    #arbejdsevneAccordion
  >
        <div class="row col-12" (keydown.enter)="$event.preventDefault()" >
            <div class="col-12 pt-2 pb-3">
                Du er ikke registreret i SU-systemet på din nuværende uddannelse.<br /> For at aktivere dine uddannelsesoplysninger i SU-systemet, skal du lave en ansøgning om almindelig SU via minSU. Som svar på ansøgningen, får du en støttemeddelelse i Digital Post. Når du har modtaget den, kan du lave en ansøgning om fødselsstøtte.<br /><br /> Du kan også henvende dig på dit uddannelsessted, som kan hjælpe dig med at aktivere din uddannelse i SU-systemet, så du kan søge om fødsels-støtte.
            </div>
        </div>
    </app-accordion>

    <app-accordion
    *ngIf="studerendeVis"
    class="col-12 pb-3"
    [ariaLabel]="' Gemt sektion for alle andre end folk der ikke er registreret med en aktiv uddannelse'"
    [h]="2"
    [overskrift]="' Jeg går på en ungdomsuddannelse'"
    [vis]="uuVis"
    [scrollIntoView]="'smart'"
    (vis$)="uuHarmonika($event)"
    #arbejdsevneAccordion
  >
        <div class="row col-12" (keydown.enter)="$event.preventDefault()">
            <div class="col-12 pt-2 pb-3">
                Du er ikke registreret i SU-systemet på din nuværende uddannelse.<br /> For at aktivere dine uddannelsesoplysninger i SU-systemet, skal du lave en ansøgning om almindelig SU via minSU. Som svar på ansøgningen, får du en støttemeddelelse i Digital Post. Når du har modtaget den, kan du lave en ansøgning om fødselsstøtte.<br /><br /> Du kan også henvende dig på dit uddannelsessted, som kan hjælpe dig med at aktivere din uddannelse i SU-systemet, så du kan søge om fødsels-støtte.
            </div>
            <div class="col-12" #topElement></div>
        </div>
    </app-accordion>

    <div class="col-12 pt-3">
        <div class="h2">Ansøgning</div>

        <div class="f2">Her kan du søge om fødsels-støtte efter de nye regler, der trådte i kraft 1. august 2022.<br />
        Er du allerede tildelt fødselsrater eller -klip før 1. august 2022, og ønsker du at ændre din udbetalingsplan, skal du <a href="https://su.dk/10896" target="_blank">kontakte en SU-medarbejder på dit uddannelsessted.</a><br />
        Udfylder du ansøgningen her, har det konsekvenser for din tidligere tildeling på grund af de nye regler.<br />
Læs mere om de <a href="https://su.dk/11106" target="_blank">nye regler på su.dk her.</a>
        </div>

        <div class=" pt-3">
            <button [disabled]="!ansoegningVis"  (click)="startAnsoegning()" type="submit"
                id="startAnsoegningKnap" class="btn btn-primary btn-lg d-block w-100">Søg fødsels-støtte</button>
        </div>
    </div>

    <div class="col-12 pt-3">
        <div class="h2"> Udbetalingsplan</div>
        <div class="f2">Hvis du allerede er godkendt til fødsels-støtte efter de nye regler, kan du udfylde, se og rette i din udbetalingsplan herunder.</div>
        <div class="pt-3">
            <button [disabled]="!udbetalingVis" (click)="startUdbetaling()" type="submit"
                class="btn btn-primary btn-lg d-block w-100">Udbetalingsplan</button>
        </div>
    </div>

    <div class="col-12 pt-3">
        <div class="h2">Overfør fødsels-støtte</div>
        <div class="f2"> Skal kun udfyldes, hvis du ønsker at overføre fødsels-støtte til den anden forælder.</div>
        <div class="pt-3">
                <button type="submit"  [disabled]="!overfoerVis"  onClick=" parent.location='https://blanket.virk.dk/blanketafvikler/orbeon/fr/nem_b/81_bc8bed80c9429e074542456ec41172d8659b16bb/new?fr-language=da'"  class="btn btn-primary btn-lg d-block w-100">Overfør fødsels-støtte</button>
        </div>
    </div>
</div>
