import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Router } from '@angular/router';
import { UdbetalingsService2 } from '../../../core/udbetalings2.service';
import { UdbetalingService } from '../udbetaling.service';

@Component({
  selector: 'app-udbetaling-kvittering',
  templateUrl: './udbetaling-kvittering.component.html',
  styleUrls: ['./udbetaling-kvittering.component.scss']
})
export class UdbetalingKvitteringComponent implements OnInit {

  @Input() formUdbetalingMaaned: UntypedFormGroup;
  postArray:String[];
  maaneder:any;
  stoetteAar?;
  stoetteAarPlus1: number;
  stoetteAarPlusString?;

  constructor(
    private udbetalingsService2: UdbetalingsService2,
    private udbetalingService: UdbetalingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.udbetalingService.kvitteringsData !== undefined){
    this.postArray = this.udbetalingService.kvitteringsData.maaneder.map((maaned)=>maaned.udbetalingsType);
    this.udbetalingService.kvitteringsData.maaneder.map((maaned) => {
       maaned.udbetalingsTypeTekst = this.udbetalingService.getRatestatustekst(maaned.udbetalingsType);
      });
    this.maaneder = this.udbetalingService.kvitteringsData.maaneder;
          //her omregnes tre cifret "222" årstal fra backend til læsbart 4 cifret årstal til frontend "2022"
          this.stoetteAar = this.udbetalingsService2.udbetaling$.value.stoetteAar;
          this.stoetteAar = this.stoetteAar.slice(0, 1) + 0 + this.stoetteAar.slice(1, 3);
          this.stoetteAarPlus1 = parseInt(this.stoetteAar);
          this.stoetteAarPlus1 = this.stoetteAarPlus1 + 1;
          this.stoetteAarPlusString = this.stoetteAarPlus1.toString();
          
          //her bliver kvitteringdata gemt i sessionStorage
          sessionStorage.setItem('maaneder', JSON.stringify(this.maaneder));
          sessionStorage.setItem('stoetteAar',this.stoetteAar);
          sessionStorage.setItem('stoetteAarPlusString',this.stoetteAarPlusString);
        } else {
          //når man genindlæser kvitteringssiden bliver kvitteringsdata hentet fra sessionStorage
          let gemteMaanederData = JSON.parse(sessionStorage.getItem('maaneder'));
          let gemteStoetteAarData = JSON.parse(sessionStorage.getItem('stoetteAar'));
          let gemteStoetteAarPlusStringData = JSON.parse(sessionStorage.getItem('stoetteAarPlusString'));
          this.maaneder = gemteMaanederData;
          this.stoetteAar = gemteStoetteAarData;
          this.stoetteAarPlus1 = Number(gemteStoetteAarPlusStringData);
        }
  }
 
  gaaTilForside(): void {
    this.router.navigate(['/'])
      .catch((error) => {
        console.log('forside: navigate til /forside fejlede. error=', error);
      });
  }
  
}
