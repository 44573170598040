<form [formGroup]="form">
  <div class="row">
    <app-accordion
      class="col-12"
      [ariaLabel]="'Åben sektion med samtykke'"
      [h]="2"
      [overskrift]="'Oplysninger om dig'"
      [status]="status"
      [userTrackingUrl]="'/ansoegning/samtykke'"
      [vis]="samtykkeVis"
      (vis$)="samtykkeVis = $event"
      #samtykkeAccordion
    >
      <div class="row col-12">
        <div class="col-12">
          <div class="form-check">
            <input id="samtykke" name="samtykke" type="checkbox" class="form-check-input" formControlName="samtykke"/>
            <label for="samtykke" class="form-check-label">
              Jeg er informeret om, at Uddannelses- og Forskningsstyrelsen behandler personlige oplysninger om mig til brug for behandling af mine SU-sager. Læs mere om behandling af personoplysninger i Uddannelses- og Forskningsstyrelsen på <a href="https://su.dk/11907" target="_blank"><span style="color: #6610f2;">su.dk/oplysninger-om-dig</span></a>.
            </label>
          </div>
        </div>
      </div>
    </app-accordion>
  </div>
</form>
