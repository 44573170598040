import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-afslag',
  templateUrl: './afslag.component.html',
  styleUrls: ['./afslag.component.scss']
})
export class AfslagComponent {

  @Input() kvitteringData: any;
  @Input() maaned: any;

  constructor(private router: Router) { }

  gaaTilForside(): void {
    this.router.navigate(['/']).catch((error) => {
        console.log('forside: navigate til /forside fejlede. error=', error);
      });
  }
}
