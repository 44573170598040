<form [formGroup]="form">
  <div class="row">
    <div class="col-12 pb-4 text-center">
      <h1>Søg fødsels-støtte</h1>
      <br />
      Du kan få op til 9 måneders fødsels-støtte.
    </div>
    <div *ngIf="ingenKomUdd" class="col-12">
      <app-sekvensnr
        class="pt-3"
        [form]="form"
        (naesteTrinOutput$)="naesteTrin($event)"
        [naesteTrinInput$]="naesteTrinInput$"
      >
      </app-sekvensnr>
    </div>

    <app-studieforloeb
      class="col-12 pt-3"
      [form]="form.controls['studieforloeb']"
      (naesteTrinOutput$)="naesteTrin($event)"
      [naesteTrinInput$]="naesteTrinInput$"
    >
    </app-studieforloeb>

    <div class="col-12 pt-3">
      <div class="row">
        <app-termin
          [form]="form"
          (naesteTrinOutput$)="naesteTrin($event)"
          class="col-12"
        ></app-termin>
        <app-enligforaeldre
          class="col-12 pt-3"
          [form]="form"
          [naesteTrinInput$]="naesteTrinInput$"
          (naesteTrinOutput$)="naesteTrin($event)"
        >
        </app-enligforaeldre>
      </div>
    </div>

    <div class="col-12 py-3">
      <app-samtykke [form]="form" [naesteTrinInput$]="naesteTrinInput$"></app-samtykke>
    </div>

    <div class="col-12 pt-3">
      <button
        (click)="sendAnsoegning()"
        type="submit"
        [disabled]="!form.valid"
        class="btn btn-primary btn-lg d-block w-100"
      >
        Send ansøgning
      </button>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center">
      <fa-icon
        [icon]="faSpinner"
        [spin]="true"
        [size]="'2x'"
        [pulse]="true"
      ></fa-icon>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3" role="alert">
      Sender ansøgning (kan tage flere minutter)
    </div>
  </div>

</form>