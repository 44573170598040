import { Component, OnInit, Input } from '@angular/core';

import { AnsoegningService } from '../ansoegning/ansoegning.service';
import { ApiAnsoegning } from '../ansoegning/api-ansoegning';
import { Kvittering } from './kvittering';
import { ProfilService } from '../../core/profil.service';

@Component({
  selector: 'app-kvittering',
  templateUrl: './kvittering.component.html',
  styleUrls: ['./kvittering.component.scss']
})
export class KvitteringComponent implements OnInit {

  @Input() ansoegning: ApiAnsoegning;
  kvittering: any;
  public kvitteringData: Kvittering;
  termin: string;
  tildeltKlip: string;
  enlig: string;
  cpr: string;
  kvitteringssideNr: number;
  kvitteringsside: string;
  barn: any;
  valgtBarn: any;
  morFar: string;
  uddBopal: string;
  fraTrukketKlip: string;
  fodseldt: any;
  resultatAfUdregning: any;
  maanedTekstTildeltKlip: string;
  maanedTekstFraTrukketKlip: string;
  dato: Date;
  terminsMaaned: string;
  IkkeRegisteretBarn: boolean = false;
  kvitteringssideVis: boolean = false;
  kvitteringssideAfslagVis: boolean = false;
  harUddBopalEnlig: boolean = false;

  constructor(private ansoegningService: AnsoegningService, private profilService: ProfilService) { }

  ngOnInit() {
    this.ansoegning = this.ansoegningService.apiAnsoegning;
    if (this.ansoegning !== undefined) {
      this.kvittering = this.ansoegningService.kvitteringData;
      this.enlig = this.ansoegningService.apiAnsoegning.enlig;
      this.barn = this.kvittering[0].barn;
      this.kvitteringsside = this.kvittering[0].kvitteringsside;
      this.fraTrukketKlip = this.kvittering[0].fraTrukketklip;
      this.fodseldt = this.kvittering[0].fodseldt;
      this.valgtBarn = this.ansoegningService.apiAnsoegning.valgtBarn;
      this.uddBopal = this.kvittering[0].uddBopal;
      this.tildeltKlip = this.kvittering[0].tildeltKlip;
      var valgtTermin = this.ansoegningService.apiAnsoegning.termin;
      if (valgtTermin.length == 7) {
        valgtTermin = this.valgtBarn;
      } else {
        this.IkkeRegisteretBarn = true;
      }
      var splitDato = valgtTermin.split('-');
      this.dato = new Date(parseInt(splitDato[2]), parseInt(splitDato[1]) - 1, parseInt(splitDato[0]));
      let maaned = this.dato.toLocaleDateString('da-DK', { month: 'long', year: 'numeric' });
      this.termin = maaned;
      this.hentMaanedTekst();
      this.kvitteringssideNr = Number(this.kvitteringsside);

      //bruges til tilsagnssitationer 
      if (this.kvitteringssideNr >= 1 && this.kvitteringssideNr < 8) {
        this.kvitteringssideVis = true;
        if(this.uddBopal == 'J' && this.enlig == 'J') {       
          this.harUddBopalEnlig = true;
        }
        //bruges til tilsagnssitationer med kalender og restklip beregning
        if (this.kvitteringssideNr > 3 && this.kvitteringssideNr < 8) {
          this.udRegnKlip();
          this.tildeltKlip = this.kvittering[0].tildeltKlip;
        }
      }

      //bruges til afslagssitationer  
      if (this.kvitteringssideNr > 19 && this.kvitteringssideNr < 36) {
        this.kvitteringssideAfslagVis = true;
        if (this.kvitteringssideNr == 20) {
          this.cpr = this.profilService.profil$.value.cpr;
          this.afgoereMorFar(this.cpr);
        }
      }

      this.barn.filter((element) => {
        return element !== '';
      });
      this.barn.forEach((element, index) => {
        this.barn[index] = this.barn[index].slice(0, 2) + '-' + this.barn[index].slice(2, 4) + '-20' + this.barn[index].slice(4, 6);
      });

      this.kvitteringData = {
        kvitteringssideVis: this.kvitteringssideVis,
        kvitteringssideAfslagVis: this.kvitteringssideAfslagVis,
        kvitteringsside: this.kvitteringsside,
        termin: this.termin,
        cpr: this.cpr,
        morFar: this.morFar,
        terminsMaaned: this.terminsMaaned,
        tildeltKlip: this.tildeltKlip,
        barn: this.barn,
        IkkeRegisteretBarn: this.IkkeRegisteretBarn,
        uddBopal: this.uddBopal,
        enlig: this.enlig,
        fraTrukketKlip: this.fraTrukketKlip,
        resultatAfUdregning: this.resultatAfUdregning,
        maanedTekstTildeltKlip: this.maanedTekstTildeltKlip,
        maanedTekstFraTrukketKlip: this.maanedTekstFraTrukketKlip,
        harUddBopalEnlig: this.harUddBopalEnlig
      };
      //her bliver kvitteringdata gemt i sessionStorage
      sessionStorage.setItem('kvitteringData', JSON.stringify(this.kvitteringData));
    }
    //når man genindlæser kvitteringssiden bliver kvitteringsdata hentet fra sessionStorage
    else {
      let gemteKvitteringData = JSON.parse(sessionStorage.getItem('kvitteringData'));
      this.kvitteringData = gemteKvitteringData;
    }
    console.log('kvitteringsside nr: ',this.kvitteringsside);
  }

  public afgoereMorFar(cpr: string) {
    const cprSidstTalStr = String(cpr).slice(-1);
    const cprSidstTalNum = Number(cprSidstTalStr);
    let terminsDato = this.dato;
    let dato = new Date(terminsDato.getFullYear(), terminsDato.getMonth());
    let nuvaerendeMåned = dato.getMonth();
 
    //tjek hvis tal er lige tal
    if (cprSidstTalNum % 2 == 0) {
      dato.setMonth(nuvaerendeMåned - 3);
      this.morFar = 'Som mor kan du få fødselsels-støtte fra to måneder før terminsmåneden';
      let maanedOgAar = dato.toLocaleDateString('da-DK', { month: 'long', year: 'numeric' });
      this.terminsMaaned = maanedOgAar;
    }
    //tjek hvis tal er ulige tal
    else {
      dato.setMonth(nuvaerendeMåned - 1);
      this.morFar = 'Som far eller medmor kan du få fødselsels-støtte fra terminsmåneden';
      let maanedOgAar = dato.toLocaleDateString('da-DK', { month: 'long', year: 'numeric' });
      this.terminsMaaned = maanedOgAar;
    }
  }

  public udRegnKlip(): String {
    let fraTrukketKlip = Number(this.fraTrukketKlip);
    let tildeltKlip = Number(this.tildeltKlip);
    let total = fraTrukketKlip + tildeltKlip;
    this.resultatAfUdregning = total;
    return this.resultatAfUdregning;
  }

  public hentMaanedTekst() {
    let fraTrukketKlip = Number(this.fraTrukketKlip);
    let tildeltKlip = Number(this.tildeltKlip);
    this.maanedTekstTildeltKlip = 'måned';
    this.maanedTekstFraTrukketKlip = 'måned';
    if (tildeltKlip != 1) {
      this.maanedTekstTildeltKlip = 'måneder';
    }
    if (fraTrukketKlip != 1) {
      this.maanedTekstFraTrukketKlip = 'måneder';
    }
  }
}