<form [formGroup]="form">
<div class="row">
  <app-accordion
    class="col-12"
    [ariaLabel]="'Åben sektion med startmåned  for foedselsstoettesansøgning'"
    [h]="2"
    [overskrift]="'Vælg uddannelse'"
    [status]="status"
    [statusTekst]="statusTekst"
    [userTrackingUrl]="'/ansoegning/sekvensnr'"
    [vis]="visSekvensnr"
    (vis$)="saetVisSekvensnr($event)"
  >
      <div class=" pt-3" role="radiogroup" aria-labelledby="sekvensnr">
      <div class="col-12">
      <p>
        Vi kan se, at du er indskrevet på to uddannelser. Du skal vælge
        hvilken af de to, du ønsker at få fødsels-støtte til:
      </p>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          role="radio"
          name="sekvensnr"
          id="sekvensnr"
          value="{{ sekvensnrNuv }}"
          formControlName="sekvensnr"
          (click)="vaelgCheckBox($event)"
          (keydown.enter)="$event.preventDefault()"
          />
          <label class="form-check-label" for="sekvensnr">{{ instnavnNuv }} {{ retningnavnNuv }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="form-check">
          <input
              class="form-check-input"
              type="radio"
              role="radio"
              name="sekvensnr"
              id="sekvensnr2"
              value="{{ sekvensnrKom }}"
              formControlName="sekvensnr"
              (click)="vaelgCheckBox($event)"
              (keydown.enter)="$event.preventDefault()"
            />
          <label class="form-check-label" for="sekvensnr2">{{ instnavnKom }} {{ retningnavnKom }}</label>
        </div>
      </div>
    </div>
    </app-accordion>
  </div>
</form>